import { createGlobalStyle } from 'styled-components';

export const BlackGlobalStyle = createGlobalStyle`
    body {
      background-color: black;
      color: white;

      input:-webkit-autofill {
        box-shadow: 0 0 0 30px black inset !important;
        -webkit-box-shadow: 0 0 0 30px black inset !important;
        -webkit-text-fill-color: white !important;
      }
    }
`;
