import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import BackButton from '../../components/back-button';
import ButtonsDiv from '../../components/buttons-div';
import ConfirmButton from '../../components/confirm-button';
import ErrorLabel from '../../components/error-label';
import MainForm from '../../components/main-form';
import { headers } from '../../shared/config';
import { randomApi } from '../../shared/random';
import { validateImg, validateStringSelect } from '../../shared/validators';
import { RegisterForm } from '../Register';
import ErrorIcon from '@mui/icons-material/Error';
import { StyledInputAlert } from './step3';

/** Definição de componentes */
const StyledMenuItem = styled(MenuItem)`
  max-width: 100% !important;
  overflow: auto !important;
  font-size: 14px !important;
`;

const StyledSelect = styled(Select)`
  margin-bottom: 8px;
  max-width: min(500px, 90vw);
`;

const StyledErrorLabel = styled(ErrorLabel)`
  margin-top: -4px;
  margin-bottom: 10px;
  @media (max-width: 700px) {
    font-size: 10px !important;
  }
`;

const GreenLabel = styled(StyledErrorLabel)`
  margin-top: -4px;
  margin-bottom: 10px;
  color: #7ddd00;
  @media (max-width: 700px) {
    font-size: 10px !important;
  }
`;

const StyledModalP = styled.p`
  font-size: 15px;
  width: 100%;
  text-align: left;
  line-height: 16px;
`;

const StyledFileUploadIcon = styled(FileUploadIcon)`
  @media (max-width: 700px) {
    font-size: 14px !important;
  }
`;

/** Definição de interfaces */
interface IProps {
  updateForm: Function;
  candidateToken: string;
  updateStep: Function;
  updateSpinner: Function;
  form: RegisterForm;
}

interface Step5Form {
  enem_year: string;
}

interface Step5FormValid {
  enem_year: boolean;
}

interface UploadFileResponse {
  success: boolean;
}

interface UpdateEnemResponse {
  success: boolean;
}

const Step5 = (props: IProps) => {
  /** Variaveis iniciais */
  const navigate = useNavigate();

  const { updateForm, candidateToken, updateStep, updateSpinner, form } = props;

  const enemYears = useMemo(
    () => [
      '2012',
      '2013',
      '2014',
      '2015',
      '2016',
      '2017',
      '2018',
      '2019',
      '2020',
      '2021',
      '2022',
      '2023',
    ],
    [],
  );

  /** Estados */
  const [formInfo, setFormInfo] = useState<Step5Form>({
    enem_year: '0',
  });

  const [formValid, setFormValid] = useState<Step5FormValid>({
    enem_year: true,
  });

  const [submitted, setSubmitted] = useState<boolean>(false);

  const [file, setFile] = useState<any>(null);

  const [fileValid, setFileValid] = useState<boolean>(true);

  const [checked, setChecked] = useState<boolean>(false);

  const [firstIteration, setFirstIteration] = useState<boolean>(true);

  const [errorAlert, setErrorAlert] = useState<boolean>(false);

  /** Efeito para recuperar valores salvos no form geral */
  useEffect(() => {
    if (firstIteration) {
      Object.keys(formInfo).map((key) => {
        if (form.hasOwnProperty(key)) {
          if (form[key as keyof RegisterForm]) {
            setFormInfo(() => {
              return Object.assign(formInfo, {
                [key]: form[key as keyof RegisterForm],
              });
            });
          }
        }
        return null;
      });
      setFirstIteration(false);
    }
  }, [formInfo, firstIteration, form]);

  /** Função do botão de voltar */
  const handleBackButton = () => {
    updateStep(3);
  };

  /** Função do botão de avançar */
  const handleConfirmButton = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      setFormValid((prev) => {
        return {
          ...prev,
          enem_year: validateStringSelect(formInfo.enem_year),
        };
      });

      file ? setFileValid(validateImg(file)) : setFileValid(false);

      setSubmitted(true);
    },
    [formInfo, file],
  );

  /** Efeito para visão do componente abrir no topo da tela */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /** Efeito quando todos os campos estão válidos */
  useEffect(() => {
    const uploadFile = async () => {
      try {
        const formData = new FormData();

        if (file) formData.append('enem_document', file);

        const {
          data: { success },
        } = await randomApi.post<UploadFileResponse>(
          '/enem/document',
          formData,
          {
            headers: headers(candidateToken),
            timeout: 30000,
          },
        );

        if (!success) {
          updateSpinner(false);
          navigate('/erro-processo-seletivo');
          return;
        }
      } catch {
        updateSpinner(false);
        navigate('/erro-processo-seletivo');
        return;
      }
    };

    const updateEnem = async () => {
      try {
        const {
          data: { success },
        } = await randomApi.post<UpdateEnemResponse>(`/enem/year`, formInfo, {
          headers: headers(candidateToken),
          timeout: 30000,
        });

        updateSpinner(false);

        if (!success) {
          navigate('/erro-processo-seletivo');
          return;
        }

        updateStep(5);
      } catch {
        updateSpinner(false);
        navigate('/erro-processo-seletivo');
        return;
      }
    };

    const effect = async () => {
      if (submitted) {
        setErrorAlert(false);
        let minOneFalse = false;
        for (const val in formValid) {
          if (!formValid[val as keyof Step5FormValid]) minOneFalse = true;
        }

        if (!fileValid) minOneFalse = true;

        setSubmitted(false);

        if (!minOneFalse) {
          updateSpinner(true);
          updateForm((prev: RegisterForm) => {
            return Object.assign(prev, formInfo);
          });
          await uploadFile();
          await updateEnem();
        } else {
          window.scrollTo(0, 0);
          setErrorAlert(true);
        }
      }
    };

    effect();
  }, [
    formValid,
    submitted,
    formInfo,
    updateSpinner,
    navigate,
    candidateToken,
    file,
    fileValid,
    updateStep,
    enemYears,
    updateForm,
  ]);

  return (
    <MainForm onSubmit={handleConfirmButton}>
      {errorAlert ? (
        <StyledInputAlert icon={<ErrorIcon fontSize="inherit" />}>
          Por favor, revise os valores dos campos
        </StyledInputAlert>
      ) : (
        <></>
      )}
      <FormControl>
        <InputLabel
          sx={{ color: 'white !important', marginTop: '8px' }}
          id="enem-year-select-label"
        >
          Ano do ENEM *
        </InputLabel>
        <StyledSelect
          labelId="enem-year-select-label"
          value={formInfo.enem_year}
          label="Ano do ENEM *"
          onChange={({ target: { value } }) => {
            setFormValid({ ...formValid, enem_year: true });
            setFormInfo((prev) => {
              return { ...prev, enem_year: value as string };
            });
          }}
          sx={
            formValid.enem_year
              ? {
                  marginTop: '8px',
                  '.MuiSelect-select': {
                    color: 'white !important',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                    borderWidth: '2px !important',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                    borderWidth: '2px !important',
                  },
                }
              : {
                  marginTop: '8px',
                  '.MuiSelect-select': {
                    color: 'white',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red !important',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red',
                    borderWidth: '2px !important',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red',
                    borderWidth: '2px !important',
                  },
                }
          }
        >
          <StyledMenuItem disabled value={'0'}>
            <em>Selecione</em>
          </StyledMenuItem>
          {enemYears.map((value, index) => (
            <StyledMenuItem value={value} key={index + 1}>
              {value}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </FormControl>
      {formValid.enem_year ? (
        <></>
      ) : (
        <StyledErrorLabel id="enemYearError">
          É necessário selecionar o ano do enem
        </StyledErrorLabel>
      )}
      <Button
        variant="contained"
        component="label"
        startIcon={<StyledFileUploadIcon />}
        sx={
          fileValid
            ? {
                backgroundColor: '#7ddd00',
                color: 'black',
                fontWeight: '700',
                height: '50px',
                '&:hover': {
                  backgroundColor: '#549d02',
                },
                margin: '8px 0px',
                marginTop: '6px',
                textAlign: 'center',
                padding: '0 3px',
                '@media screen and (max-width: 700px)': {
                  fontSize: '9px',
                },
              }
            : {
                backgroundColor: 'rgb(211, 92, 98)',
                color: 'black',
                fontWeight: '700',
                height: '50px',
                '&:hover': {
                  backgroundColor: 'rgb(211, 92, 98)',
                  opacity: 0.9,
                },
                margin: '8px 0px',
                marginTop: '6px',
                textAlign: 'center',
                padding: '0 3px',
                '@media screen and (max-width: 700px)': {
                  fontSize: '9px',
                },
              }
        }
      >
        {file ? file.name : 'Cópia/Foto do documento (RG e CPF) *'}
        <input
          onChange={({ target: { files } }) => {
            setFileValid(true);
            setFile(files ? files[0] : null);
          }}
          accept="image/png, image/jpeg, application/pdf"
          type="file"
          hidden
          name="inputField"
          value={file?.filename}
        />
      </Button>
      {fileValid ? (
        <GreenLabel id="docImgError">
          Arquivo .png, .jpg, .jpeg ou .pdf de até 4MB.
        </GreenLabel>
      ) : (
        <StyledErrorLabel id="docImgError">
          É necessário um arquivo .png, .jpg, .jpeg ou .pdf de até 4MB.
        </StyledErrorLabel>
      )}
      <FormControlLabel
        sx={{
          marginBottom: '8px',
        }}
        control={
          <Checkbox
            sx={{
              display: 'inline-block',
              verticalAlign: 'middle',
              color: '#7ddd00',
              marginTop: '12px',
              '&.Mui-checked': {
                color: '#7ddd00',
              },
            }}
            onChange={() => {
              setChecked(!checked);
            }}
            value={checked}
          />
        }
        label={
          // <StyledModalP>
          //   Estou ciente que serão aceitos somente os anos do ENEM 2018, 19, 20,
          //   21 e 22. Além disso, declaro que estou ciente de que{' '}
          //   <b>confirmarei</b> a minha escolha do ano do ENEM, dentre as opções
          //   dispostas, caso seja aprovado para segunda fase, em formulário
          //   disponibilizado pela XPE.
          // </StyledModalP>
          <StyledModalP style={{ marginTop: '12px' }}>
            Estou ciente que serão aceitos somente os anos do ENEM de 2012 a
            2023.{' '}
          </StyledModalP>
        }
      />
      <ButtonsDiv>
        <ConfirmButton type="submit" disabled={!checked}>
          AVANÇAR
        </ConfirmButton>
        <BackButton type="button" onClick={handleBackButton}>
          VOLTAR
        </BackButton>
      </ButtonsDiv>
    </MainForm>
  );
};

export default Step5;
