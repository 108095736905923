import styled from 'styled-components';

const MainForm = styled.form`
  display: flex;
  flex-direction: column;
  padding-top: 4vw !important;
  width: min(500px, 90vw) !important;

  input:-webkit-autofill {
    box-shadow: 0 0 0 30px black inset !important;
    -webkit-box-shadow: 0 0 0 30px black inset !important;
    -webkit-text-fill-color: white !important;
  }
`;

export default MainForm;
