import { Route, Routes } from 'react-router-dom';

import ProcessoSeletivo from 'pages/processo-seletivo';
// import ConfirmationProcessoSeletivo from 'pages/processo-seletivo/shared/simple-pages/confirmation';
import ErrorProcessoSeletivo from 'pages/processo-seletivo/shared/simple-pages/error';
// import RegisteredProcessoSeletivo from 'pages/processo-seletivo/shared/simple-pages/registered';
import SuccessProcessoSeletivo from 'pages/processo-seletivo/shared/simple-pages/success';
import Home from './home/Home';
// // import EnrollmentRequested from './shared/simple-pages/enrollment-requested';
// import NotApproved from './shared/simple-pages/not-approved';

// const HomeRedirect = () => {
//   window.location.href = 'https://www.xpeducacao.com.br/';
//   return null;
// };

const ProcessoSeletivoRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/inscricao" element={<ProcessoSeletivo page="register" />} />
      <Route
        path="/erro-processo-seletivo"
        element={<ErrorProcessoSeletivo />}
      />
      <Route path="/sucesso-inscricao" element={<SuccessProcessoSeletivo />} />

      {/* 
      
      <Route path="/confirmacao" element={<ConfirmationProcessoSeletivo />} />
      
      <Route
        path="/inscrito-processo-seletivo"
        element={<RegisteredProcessoSeletivo />}
      />
       */}
      {/* <Route
        path="/"
        // element={<HomeRedirect />}
        element={<Navigate to="/cadastro" replace />}
      /> */}

      {/* Matrícula */}
      {/* <Route
        path="/matricula-processo-seletivo"
        element={<HomeRedirect />}
        // element={<ProcessoSeletivo page="term" />}
      />
      <Route
        path="/nao-aprovado-processo-seletivo"
        element={<HomeRedirect />}
        // element={<NotApproved />}
      />
      <Route
        path="/matricula-solicitada-processo-seletivo"
        element={<HomeRedirect />}
        // element={<EnrollmentRequested />}
      /> */}
    </Routes>
  );
};

export default ProcessoSeletivoRoutes;
