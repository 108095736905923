import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Alert } from '@mui/material';
import styled from 'styled-components';
import InputTextField from '../components/input-text-field';
import MainForm from '../components/main-form';
import ConfirmButton from '../components/confirm-button';
import ErrorLabel from '../components/error-label';
import { CpfMask } from '../components/masks';
import { validateCpf, validateEmail } from '../shared/validators';
import { randomApi } from '../shared/random';
import backgroundTransparentBottom from '../../../shared/assets/images/background_transparent_bottom.png';
import { BlackGlobalStyle } from '../../../shared/utils/styles/black_global_style';
import { RadioCanadaFontStyle } from '../../../shared/utils/styles/radio_canada_font_style';
import { MainContainer } from '../../../shared/components/main/main_container';
import Header from '../../../shared/components/main/header/xpe_faculdade';
import Footer from '../../../shared/components/main/footer/xpe_faculdade';
import { formatEmail } from '../../../shared/utils/functions/dataCleaners';
import ErrorIcon from '@mui/icons-material/Error';

/** Definição de componentes */
const ProgressDiv = styled.div`
  display: flex !important;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  width: 100%;
  height: calc(100%);
`;

const StyledCircularProgress = styled(CircularProgress)`
  position: fixed !important;
  top: 50% !important;
  color: #7ddd00 !important;
  width: 50px !important;
  height: 50px !important;
`;

const MainDiv = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
`;

const StyledMainForm = styled(MainForm)`
  margin: 25px auto;
  padding-top: 0 !important;
  width: min(500px, 90vw) !important;
  justify-content: center;
  align-items: center;

  label {
    width: min(355px, 77vw) !important;
  }
`;

export const StyledInputAlert = styled(Alert)`
  display: flex !important;
  align-items: center !important;
  width: min(390px, 77vw) !important;
  background-color: rgb(211, 92, 98) !important;
  font-family: 'Radio Canada', sans-serif !important;
  color: white !important;
  font-size: 13px !important;
  margin-top: 20px !important;
  margin-bottom: -15px !important;
  height: 30px !important;

  svg {
    color: white !important;
    margin-top: -4px !important;
  }

  @media only screen and (min-width: 1200px) {
    height: 30px !important;
    font-size: 15px !important;
  }

  div {
    margin-top: 2px;
  }
`;

const StyledIntro = styled.p`
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 40px;
  text-align: center;
  font-size: 30px;
  @media (max-width: 700px) {
    font-size: 22px;
  }
`;

const SubTitle = styled.div`
  margin: 5px;
  font-size: 24px;
  text-align: center;
  @media (max-width: 700px) {
    font-size: 20px;
  }
`;

const SubP = styled.div`
  margin: 5px;
  color: #cccccc;
  font-size: 16px;
  text-align: center;
  @media (max-width: 700px) {
    font-size: 15px;
  }
`;

const StyledGradient = styled.span`
  background-image: linear-gradient(to right, #7ddd00, yellow);
  color: black;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const OptionsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

const OptionValueDiv = styled.div`
  width: min(500px, 90vw);
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonsDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const StyledConfirmButton = styled(ConfirmButton)`
  height: 50px !important;
  width: 376px !important;
  border-radius: 8px !important;
  @media (max-width: 700px) {
    width: 326px !important;
  }
`;

const StyledRegistrationDiv = styled.div`
  text-align: center;
`;

const StyledStartButton = styled.button`
  padding: 0;
  background-color: black;
  color: #cccccc;
  border: none;
  font-size: 16px;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
`;

const StyledCanvasImage = styled.img`
  position: absolute;
  bottom: 70px;
  left: 0;
  width: 30vw;
  z-index: -1;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const ButtonText = styled.p`
  margin: 10px;
  color: #005c00;
  text-transform: none;
  font-size: 15px;
`;

const StyledInputTextField = styled(InputTextField)`
  width: 376px !important;
  @media (max-width: 700px) {
    width: 326px !important;
  }
`;

/** Definição de interfaces */
interface HomeForm {
  email: string;
  cpf: string;
}

interface HomeFormValid {
  email: boolean;
  cpf: boolean;
}

interface LoginResponse {
  success: boolean;
  response: string;
}

const Home = () => {
  /** Variaveis iniciais */
  const navigate = useNavigate();

  /** Estados */
  const [formInfo, setFormInfo] = useState<HomeForm>({
    email: '',
    cpf: '',
  });

  const [formValid, setFormValid] = useState<HomeFormValid>({
    email: true,
    cpf: true,
  });

  const [submitted, setSubmitted] = useState<boolean>(false);

  const [spinner, setSpinner] = useState<boolean>(false);

  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const [loginInvalid, setLoginInvalid] = useState<boolean>(false);

  /** Efeito para controlar a ativação do botão de submit */
  useEffect(() => {
    if (formInfo.email && formInfo.cpf) {
      setIsButtonDisabled(false);
    }
  }, [formInfo]);

  /** Efeito para controlar a ativação do botão de submit */
  const handleStartButton = () => {
    navigate('/inscricao');
  };

  /** Função do botão de avançar */
  const handleContinueButton = useCallback(
    (event) => {
      event.preventDefault();

      setFormValid((prev: any) => {
        return { ...prev, email: validateEmail(formInfo.email) };
      });
      setFormValid((prev: any) => {
        return { ...prev, cpf: validateCpf(formInfo.cpf) };
      });

      setSubmitted(true);
    },
    [formInfo],
  );

  /** Efeito quando todos os campos estão válidos */
  useEffect(() => {
    const handleSubmitForm = async () => {
      try {
        const {
          data: { success, response },
        } = await randomApi.post<LoginResponse>('/candidates/login', formInfo, {
          timeout: 30000,
        });

        setSpinner(false);

        if (!success) {
          setLoginInvalid(true);
          return;
        }

        navigate('/inscricao', { state: { candidateToken: response } });
        return;
      } catch {
        setSpinner(false);
        navigate('/erro-processo-seletivo');
        return;
      }
    };

    const effect = async () => {
      if (submitted) {
        setLoginInvalid(false);
        let minOneFalse = false;

        for (const val in formValid) {
          if (!formValid[val as keyof HomeFormValid]) minOneFalse = true;
        }

        setSubmitted(false);

        if (!minOneFalse) {
          setSpinner(true);

          await handleSubmitForm();
        }
      }
    };

    effect();
  }, [formValid, submitted, formInfo, navigate]);

  return (
    <>
      <MainContainer>
        <BlackGlobalStyle />
        <RadioCanadaFontStyle />
        <Header home={true} />
        {spinner ? (
          <ProgressDiv>
            <StyledCircularProgress />
          </ProgressDiv>
        ) : (
          <></>
        )}
        <MainDiv>
          <StyledIntro>
            Seja bem-vindo ao processo seletivo da graduação da{' '}
            <StyledGradient>Faculdade XP Educação</StyledGradient>
          </StyledIntro>
          <OptionsDiv>
            <OptionValueDiv>
              <SubTitle>Continuar de onde parei</SubTitle>
              <SubP>Já concluí algumas etapas. Quero continuar:</SubP>
              {loginInvalid ? (
                <StyledInputAlert icon={<ErrorIcon fontSize="inherit" />}>
                  Credenciais de login inválidas
                </StyledInputAlert>
              ) : (
                <></>
              )}
              <StyledMainForm>
                <StyledInputTextField
                  focused
                  required
                  label="Email"
                  placeholder="candidato@xpeducacao.com.br"
                  type="text"
                  className="inputField"
                  onChange={({ target: { value } }) =>
                    setFormInfo((prev) => {
                      setFormValid({ ...formValid, email: true });
                      return { ...prev, email: formatEmail(value) };
                    })
                  }
                  sx={
                    formValid.email
                      ? {
                          '& .MuiOutlinedInput-root': {
                            color: 'white !important',
                            '&.Mui-focused fieldset': {
                              borderColor: 'rgba(255, 255, 255, 0.12)',
                            },
                            '&:hover fieldset': {
                              borderColor: 'rgba(255, 255, 255, 0.12)',
                            },
                            '& fieldset': {
                              borderColor: 'rgba(255, 255, 255, 0.12)',
                              borderRadius: '8px !important',
                            },
                          },
                        }
                      : {
                          '& .MuiOutlinedInput-root': {
                            color: 'white !important',
                            '&.Mui-focused fieldset': {
                              borderColor: 'red',
                            },
                            '&:hover fieldset': {
                              borderColor: 'red',
                            },
                            '& fieldset': {
                              borderColor: 'red',
                              borderRadius: '8px !important',
                            },
                          },
                        }
                  }
                >
                  {formInfo.email}
                </StyledInputTextField>
                {formValid.email ? (
                  <></>
                ) : (
                  <ErrorLabel id="emailError">Email inválido</ErrorLabel>
                )}
                <StyledInputTextField
                  focused
                  placeholder="000.000.000-00"
                  required
                  label="CPF"
                  type="text"
                  className="inputField"
                  onChange={({ target: { value } }) =>
                    setFormInfo((prev) => {
                      setFormValid({ ...formValid, cpf: true });
                      return { ...prev, cpf: value };
                    })
                  }
                  value={formInfo.cpf}
                  InputProps={{
                    inputComponent: CpfMask as any,
                  }}
                  sx={
                    formValid.cpf
                      ? {
                          '& .MuiOutlinedInput-root': {
                            color: 'white !important',
                            '&.Mui-focused fieldset': {
                              borderColor: 'rgba(255, 255, 255, 0.12)',
                            },
                            '&:hover fieldset': {
                              borderColor: 'rgba(255, 255, 255, 0.12)',
                            },
                            '& fieldset': {
                              borderColor: 'rgba(255, 255, 255, 0.12)',
                              borderRadius: '8px !important',
                            },
                          },
                        }
                      : {
                          '& .MuiOutlinedInput-root': {
                            color: 'white !important',
                            '&.Mui-focused fieldset': {
                              borderColor: 'red',
                            },
                            '&:hover fieldset': {
                              borderColor: 'red',
                            },
                            '& fieldset': {
                              borderColor: 'red',
                              borderRadius: '8px !important',
                            },
                          },
                        }
                  }
                />
                {formValid.cpf ? (
                  <></>
                ) : (
                  <ErrorLabel id="cpfError">CPF inválido</ErrorLabel>
                )}
                <ButtonsDiv>
                  <StyledConfirmButton
                    type="submit"
                    disabled={isButtonDisabled}
                    onClick={handleContinueButton}
                  >
                    <ButtonText>Continuar</ButtonText>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z"
                        fill="#005C00"
                      />
                    </svg>
                  </StyledConfirmButton>
                </ButtonsDiv>
              </StyledMainForm>
              <StyledRegistrationDiv>
                <SubP>É a minha primeira vez por aqui.</SubP>
                <StyledStartButton type="button" onClick={handleStartButton}>
                  Quero fazer minha inscrição
                </StyledStartButton>
              </StyledRegistrationDiv>
            </OptionValueDiv>
          </OptionsDiv>
        </MainDiv>
        <StyledCanvasImage src={backgroundTransparentBottom} alt="" />
      </MainContainer>
      <Footer />
    </>
  );
};

export default Home;
