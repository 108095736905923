import AccessibleIcon from '@mui/icons-material/Accessible';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddTaskIcon from '@mui/icons-material/AddTask';
import BadgeIcon from '@mui/icons-material/Badge';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GroupsIcon from '@mui/icons-material/Groups';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import QuizIcon from '@mui/icons-material/Quiz';
import { CircularProgress, Step, StepLabel, Stepper } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import Step1 from './steps/step1';
import Step2 from './steps/step2';
import Step3, {
  CityResponse,
  FormationResponse,
  GenreResponse,
  KinshipResponse,
  StateResponse,
} from './steps/step3';
import Step4 from './steps/step4';
import Step5 from './steps/step5';
import Step6, { AssistiveTechnologyResponse } from './steps/step6';
import Step7, { EthnicResponse, ModalityResponse } from './steps/step7';
import Step8 from './steps/step8';

/** Definição de componentes */
const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-top: 20px;

  @media (min-width: 1200px) {
    padding-top: 80px;
    padding-bottom: 50px;
  }
`;

const ProgressDiv = styled.div`
  display: flex !important;
  position: fixed;
  top: 0;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  width: 100%;
  height: calc(100%);
`;

const StyledCircularProgress = styled(CircularProgress)`
  position: fixed !important;
  top: 50% !important;
  color: #7ddd00 !important;
  width: 50px !important;
  height: 50px !important;
`;

/** Definição de interfaces */
export interface RegisterForm {
  name: string;
  social_name?: string;
  cpf: string;
  email: string;
  phone: string;
  rg: string;
  birthday: string;
  nationality: string;
  genre_id: number;
  address: {
    cep: string;
    address: string;
    number: string;
    complement?: string;
    neighborhood: string;
    city_id: number;
    state: string;
  };
  formation: {
    formation_id: number;
    high_school_graduation_date: string;
    high_school_type: string;
  };
  responsible: {
    name: string;
    rg: string;
    cpf: string;
    email: string;
    kinship_id: number;
  };
  reason: string;
  courseAttribute: string;
  marketSituation: string;
  occupationArea: string;
  salary: string;
  graduation: string;
  specialization: string;
  enem_year: string;
  has_disability: boolean | null;
  cid1: string;
  cid2: string;
  sync_support: boolean | null;
  resource_id: number;
  description?: string;
  ethnicGroup: number;
  modality: number;
}

const Register = () => {
  /** Variaveis iniciais */
  const location: any = useLocation();

  const navigate = useNavigate();

  /** Estados */
  const [activeStep, setActiveStep] = useState<number>(0);

  const [formInfo, setFormInfo] = useState<RegisterForm>({
    name: '',
    cpf: '',
    email: '',
    phone: '',
    rg: '',
    birthday: '',
    nationality: 'Brasileira',
    genre_id: 0,
    address: {
      cep: '',
      address: '',
      number: '',
      complement: '',
      neighborhood: '',
      city_id: 0,
      state: '0',
    },
    formation: {
      formation_id: 0,
      high_school_graduation_date: '',
      high_school_type: '0',
    },
    responsible: { name: '', rg: '', cpf: '', email: '', kinship_id: 0 },
    reason: '0',
    courseAttribute: '0',
    marketSituation: '0',
    occupationArea: '0',
    salary: '0',
    graduation: '0',
    specialization: '0',
    enem_year: '0',
    has_disability: null,
    cid1: '',
    cid2: '',
    sync_support: null,
    resource_id: 0,
    ethnicGroup: 0,
    modality: 0,
  });

  const [candidateToken, setCandidateToken] = useState<string>('');

  const [spinner, setSpinner] = useState<boolean>(false);

  const [genres, setGenres] = useState<GenreResponse[]>([]);
  const [cities, setCities] = useState<CityResponse[]>([]);
  const [formations, setFormations] = useState<FormationResponse[]>([]);
  const [kinships, setKinships] = useState<KinshipResponse[]>([]);
  const [assistiveTechnologies, setAssistiveTechnologies] = useState<
    AssistiveTechnologyResponse[]
  >([]);
  const [ethnics, setEthnics] = useState<EthnicResponse[]>([]);
  const [modalities, setModalities] = useState<ModalityResponse[]>([]);
  const [states, setStates] = useState<StateResponse[]>([]);

  /** Efeito para identificar estágio vindo do location.state */
  useEffect(() => {
    const locState = location.state;

    if (locState && locState.candidateToken) {
      const locCandidateToken = locState.candidateToken;
      setCandidateToken(locCandidateToken);

      const decodedToken: any = jwt_decode(locCandidateToken);

      if (decodedToken.stage >= 8) navigate('/sucesso-inscricao');

      const updateFormObject = {
        name: decodedToken.candidate_info.name,
        social_name: decodedToken.candidate_info.social_name,
        cpf: decodedToken.candidate_info.cpf,
        email: decodedToken.candidate_info.email,
        phone: decodedToken.candidate_info.phone,
      };

      if (decodedToken.stage >= 3) {
        Object.assign(updateFormObject, {
          rg: decodedToken.candidate_info.rg,
          birthday: decodedToken.candidate_info.birthday,
          genre_id: decodedToken.candidate_info.genre_id,
          address: {
            cep: decodedToken.candidate_info.address.cep,
            address: decodedToken.candidate_info.address.address,
            number: decodedToken.candidate_info.address.number,
            complement: decodedToken.candidate_info.address.complement,
            neighborhood: decodedToken.candidate_info.address.neighborhood,
            city_id: decodedToken.candidate_info.address.city_id,
            state: decodedToken.candidate_info.address.state,
          },
          formation: {
            formation_id: decodedToken.candidate_info.formation.formation_id,
            high_school_graduation_date:
              decodedToken.candidate_info.formation.high_school_graduation_date,
            high_school_type:
              decodedToken.candidate_info.formation.high_school_type,
          },
        });

        if (decodedToken.candidate_info.responsible) {
          Object.assign(updateFormObject, {
            responsible: {
              name: decodedToken.candidate_info.responsible.name,
              rg: decodedToken.candidate_info.responsible.rg,
              cpf: decodedToken.candidate_info.responsible.cpf,
              email: decodedToken.candidate_info.responsible.email,
              kinship_id: decodedToken.candidate_info.responsible.kinship_id,
            },
          });
        }
      }

      if (decodedToken.stage >= 4) {
        Object.assign(updateFormObject, {
          reason: decodedToken.quiz[0],
          courseAttribute: decodedToken.quiz[1],
          marketSituation: decodedToken.quiz[2],
          occupationArea: decodedToken.quiz[3],
          salary: decodedToken.quiz[4],
          graduation: decodedToken.quiz[5],
          specialization: decodedToken.quiz[6],
        });
      }

      if (decodedToken.stage >= 5) {
        Object.assign(updateFormObject, {
          enem_year: decodedToken.options.enem_year,
        });
      }

      if (decodedToken.stage >= 6) {
        Object.assign(updateFormObject, {
          has_disability: decodedToken.accessibility,
        });

        if (decodedToken.accessibility) {
          Object.assign(updateFormObject, {
            has_disability: decodedToken.accessibility.has_disability,
            cid1: decodedToken.accessibility.cid[0],
            cid2:
              decodedToken.accessibility.cid.length === 2
                ? decodedToken.accessibility.cid[1]
                : '',
            sync_support: decodedToken.accessibility.sync_support,
            resource_id: decodedToken.accessibility.resource_id,
            description: decodedToken.accessibility.description,
          });
        }
      }

      if (decodedToken.stage >= 7) {
        Object.assign(updateFormObject, {
          ethnicGroup: decodedToken.candidate_info.ethnic_id,
          modality: decodedToken.options.modality_id,
        });
      }

      setFormInfo(Object.assign(formInfo, updateFormObject));
      setActiveStep(decodedToken.stage);
    }
  }, [location.state, formInfo, navigate]);

  /** Definição dos steps */
  const steps = [
    {
      label: 'Informações Iniciais',
      icon: AccountCircleIcon,
      step: (
        <Step1
          updateForm={setFormInfo}
          updateSpinner={setSpinner}
          updateStep={setActiveStep}
          updateCandidateToken={setCandidateToken}
        />
      ),
    },
    {
      label: 'Confirmação de Cadastro',
      icon: MarkEmailReadIcon,
      step: (
        <Step2
          candidateToken={candidateToken}
          updateStep={setActiveStep}
          updateSpinner={setSpinner}
          form={formInfo}
        />
      ),
    },
    {
      label: 'Dados Pessoais',
      icon: BadgeIcon,
      step: (
        <Step3
          updateForm={setFormInfo}
          candidateToken={candidateToken}
          updateStep={setActiveStep}
          updateSpinner={setSpinner}
          form={formInfo}
          genres={genres}
          setGenres={setGenres}
          states={states}
          setStates={setStates}
          cities={cities}
          setCities={setCities}
          formations={formations}
          setFormations={setFormations}
          kinships={kinships}
          setKinships={setKinships}
        />
      ),
    },
    {
      label: 'Questionário',
      icon: QuizIcon,
      step: (
        <Step4
          updateForm={setFormInfo}
          candidateToken={candidateToken}
          updateStep={setActiveStep}
          updateSpinner={setSpinner}
          form={formInfo}
        />
      ),
    },
    {
      label: 'Informações de Prova',
      icon: AddTaskIcon,
      step: (
        <Step5
          updateForm={setFormInfo}
          candidateToken={candidateToken}
          updateStep={setActiveStep}
          updateSpinner={setSpinner}
          form={formInfo}
        />
      ),
    },
    {
      label: 'Acessibilidade',
      icon: AccessibleIcon,
      step: (
        <Step6
          updateForm={setFormInfo}
          candidateToken={candidateToken}
          updateStep={setActiveStep}
          updateSpinner={setSpinner}
          form={formInfo}
          assistiveTechnologies={assistiveTechnologies}
          setAssistiveTechnologies={setAssistiveTechnologies}
        />
      ),
    },
    {
      label: 'Dados Étnico-raciais',
      icon: GroupsIcon,
      step: (
        <Step7
          updateForm={setFormInfo}
          candidateToken={candidateToken}
          updateStep={setActiveStep}
          updateSpinner={setSpinner}
          form={formInfo}
          ethnics={ethnics}
          setEthnics={setEthnics}
          modalities={modalities}
          setModalities={setModalities}
        />
      ),
    },
    {
      label: 'Confirmação',
      icon: CheckCircleIcon,
      step: (
        <Step8
          candidateToken={candidateToken}
          updateStep={setActiveStep}
          updateSpinner={setSpinner}
          form={formInfo}
          genres={genres}
          setGenres={setGenres}
          states={states}
          setStates={setStates}
          cities={cities}
          setCities={setCities}
          formations={formations}
          setFormations={setFormations}
          kinships={kinships}
          setKinships={setKinships}
          assistiveTechnologies={assistiveTechnologies}
          setAssistiveTechnologies={setAssistiveTechnologies}
          ethnics={ethnics}
          setEthnics={setEthnics}
          modalities={modalities}
          setModalities={setModalities}
        />
      ),
    },
  ];

  /** Função para clique nos icones dos steps */
  const handleIconClick = useCallback(() => {}, []);

  return (
    <>
      <MainDiv>
        {spinner ? (
          <ProgressDiv>
            <StyledCircularProgress />
          </ProgressDiv>
        ) : (
          <></>
        )}
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{
            '& .MuiStepConnector-line': {
              borderTopWidth: '2px',
              margin: '0 1px',
              marginTop: '-1px',
            },
            '& .Mui-active': {
              '& .MuiStepConnector-line': {
                borderColor: '#7ddd00',
              },
              borderColor: 'white !important',
              color: 'white !important',
            },
            '& .Mui-completed': {
              '& .MuiStepConnector-line': {
                borderColor: '#7ddd00',
              },
              borderColor: '#7ddd00 !important',
              color: '#7ddd00 !important',
            },
            '& .Mui-disabled': {
              '& .MuiStepConnector-line': {
                borderColor: 'rgba(200,200,200, 0.7)',
              },
              borderColor: 'white',
              color: 'rgba(200,200,200, 0.7)',
            },
            '& .MuiStepIcon-root': {
              height: '40px !important',
              width: '40px !important',
              backgroundColor: 'pink',
            },
            '& .MuiStepLabel-iconContainer': {
              transform: 'scale(1.6)',
            },
            '@media (max-width: 1000px)': {
              display: 'none',
            },
          }}
        >
          {steps.map((item) => (
            <Step key={item.label}>
              <StepLabel
                sx={{
                  '& .MuiStepIcon-root': {
                    backgroundColor: 'pink',
                  },
                }}
                StepIconComponent={item.icon}
                onClick={handleIconClick}
              >
                {item.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {steps[activeStep].step}
      </MainDiv>
    </>
  );
};

export default Register;
