import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmButton from '../../components/confirm-button';
import ButtonsDiv from '../../components/buttons-div';
import ErrorLabel from '../../components/error-label';
import MainForm from '../../components/main-form';
import BackButton from '../../components/back-button';
import {
  Button,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@mui/material';
import styled from 'styled-components';
import StyledLabel from '../../components/styled-label';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InputTextField from '../../components/input-text-field';
import {
  validateCid,
  validateImg,
  validateOption,
  validateSelect,
  validateTextArea,
} from '../../shared/validators';
import { headers } from '../../shared/config';
import { randomApi } from '../../shared/random';
import { RegisterForm } from '../Register';
import ErrorIcon from '@mui/icons-material/Error';
import { StyledInputAlert } from './step3';

/** Definição de componentes */
const StyledMenuItem = styled(MenuItem)`
  max-width: 100% !important;
  overflow: auto !important;
  font-size: 14px !important;
`;

const StyledSelect = styled(Select)`
  margin-bottom: 8px;
  max-width: 500px;
`;

const NewStyledLabel = styled(StyledLabel)`
  margin-bottom: 5px;
  margin-top: 40px;
`;

const SecondStyledLabel = styled(StyledLabel)`
  margin-top: 35px;
  margin-bottom: 10px;
`;

const ThirdStyledLabel = styled(StyledLabel)`
  margin-top: 40px;
  margin-bottom: 2px;
`;

const GreenFormLabel = styled(FormControlLabel)`
  & .Mui-checked {
    color: #7ddd00 !important;
  }
  & .MuiRadio-root {
    color: white;
  }
  & .MuiCheckbox-root {
    color: white;
  }

  width: min(500px, 90vw);
`;

const StyledErrorLabel = styled(ErrorLabel)`
  margin-top: -1px;
`;

const GreenLabel = styled(StyledErrorLabel)`
  margin-top: -4px;
  margin-bottom: 10px;
  color: #7ddd00;
`;
const StyledFileUploadIcon = styled(FileUploadIcon)`
  @media (max-width: 700px) {
    font-size: 14px !important;
  }
`;

/** Definição de interfaces */
interface IProps {
  updateForm: Function;
  candidateToken: string;
  updateStep: Function;
  updateSpinner: Function;
  form: RegisterForm;
  assistiveTechnologies: AssistiveTechnologyResponse[];
  setAssistiveTechnologies: Function;
}

interface Step6Form {
  has_disability: boolean | null;
  cid1: string;
  cid2: string;
  sync_support: boolean | null;
  resource_id: number;
  description?: string;
}

interface Step6FormValid {
  has_disability: boolean;
  cid1: boolean;
  cid2: boolean;
  sync_support: boolean;
  resource_id: boolean;
  description: boolean;
}

export interface AssistiveTechnologyResponse {
  id_tecnologia_assistiva: number;
  tecnologia_assistiva: string;
}

export interface GetAssistiveTechnologiesResponse {
  success: boolean;
  response: string | AssistiveTechnologyResponse[];
}

interface UpdateAssistiveTechnologyResponse {
  success: boolean;
}

interface UploadFileResponse {
  success: boolean;
}

const Step6 = (props: IProps) => {
  /** Variaveis iniciais */
  const navigate = useNavigate();

  const {
    updateForm,
    candidateToken,
    updateStep,
    updateSpinner,
    form,
    assistiveTechnologies,
    setAssistiveTechnologies,
  } = props;

  const cids = useMemo(() => {
    return [
      'H54',
      'H54.0',
      'H54.1',
      'H54.2',
      'H54.3',
      'H54.4',
      'H54.5',
      'H54.6',
      'H54.7',
      'F70',
      'F70.0',
      'F70.1',
      'F70.8',
      'F70.9',
      'F71',
      'F71.0',
      'F71.1',
      'F71.8',
      'F71.9',
      'F72',
      'F72.0',
      'F72.1',
      'F72.8',
      'F72.9',
      'F73',
      'F73.0',
      'F73.1',
      'F73.8',
      'F73.9',
      'F78',
      'F78.0',
      'F78.1',
      'F78.8',
      'F78.9',
      'F79',
      'F79.0',
      'F79.1',
      'F79.8',
      'F79.9',
      'F84.4',
      'Z81.0',
    ];
  }, []);

  /** Estados */
  const [formInfo, setFormInfo] = useState<Step6Form>({
    has_disability: null,
    cid1: '',
    cid2: '',
    sync_support: false,
    resource_id: 0,
  });

  const [formValid, setFormValid] = useState<Step6FormValid>({
    has_disability: true,
    cid1: true,
    cid2: true,
    sync_support: true,
    resource_id: true,
    description: true,
  });

  const [submitted, setSubmitted] = useState<boolean>(false);

  const [firstIteration, setFirstIteration] = useState<boolean>(true);

  const [file, setFile] = useState<any>(null);

  const [fileValid, setFileValid] = useState<boolean>(true);

  const [errorAlert, setErrorAlert] = useState<boolean>(false);

  /** Efeito para recuperar valores salvos no form geral */
  useEffect(() => {
    if (firstIteration) {
      Object.keys(formInfo).map((key) => {
        if (form.hasOwnProperty(key)) {
          if (form[key as keyof RegisterForm] !== null) {
            setFormInfo(() => {
              return Object.assign(formInfo, {
                [key]: form[key as keyof RegisterForm],
              });
            });
          }
        }
        return null;
      });
      setFirstIteration(false);
    }
  }, [formInfo, firstIteration, form]);

  /**Efeito que recupera as tecnologias assistivas */
  useEffect(() => {
    const getAssistiveTechnologies = async () => {
      try {
        const {
          data: { success, response },
        } = await randomApi.get<GetAssistiveTechnologiesResponse>(
          '/assistive_technologies/',
          {
            headers: headers(candidateToken),
            timeout: 30000,
          },
        );

        updateSpinner(false);

        if (!success) {
          navigate('/erro-processo-seletivo');
          return;
        }

        setAssistiveTechnologies(response as AssistiveTechnologyResponse[]);
      } catch {
        updateSpinner(false);
        navigate('/erro-processo-seletivo');
        return;
      }
    };

    const effect = async () => {
      updateSpinner(true);
      if (assistiveTechnologies.length === 0) await getAssistiveTechnologies();
      updateSpinner(false);
    };

    effect();
  }, [
    updateSpinner,
    navigate,
    candidateToken,
    setAssistiveTechnologies,
    assistiveTechnologies,
  ]);

  /** Função para verificar se algum dos cids é elegivel a suporte */
  const checkSupportCid = useCallback(() => {
    return [formInfo.cid1, formInfo.cid2].some((item) =>
      cids.includes(item.toUpperCase()),
    );
  }, [cids, formInfo.cid1, formInfo.cid2]);

  /** Função do botão de voltar */
  const handleBackButton = () => {
    updateStep(4);
  };

  /** Função do botão de confirmação */
  const handleConfirmButton = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      setFormValid((prev) => {
        return {
          ...prev,
          has_disability: validateOption(formInfo.has_disability),
        };
      });

      if (formInfo.has_disability) {
        setFormValid((prev) => {
          return { ...prev, cid1: validateCid(formInfo.cid1) };
        });

        if (formInfo.cid2)
          setFormValid((prev) => {
            return { ...prev, cid2: validateCid(formInfo.cid2) };
          });

        file ? setFileValid(validateImg(file)) : setFileValid(false);

        if (checkSupportCid())
          setFormValid((prev) => {
            return {
              ...prev,
              sync_support: validateOption(formInfo.sync_support),
            };
          });

        setFormValid((prev) => {
          return { ...prev, resource_id: validateSelect(formInfo.resource_id) };
        });

        if (formInfo.description) {
          setFormValid((prev) => {
            return {
              ...prev,
              description: validateTextArea(formInfo.description ?? '', 200),
            };
          });
        }
      } else {
        setFormValid((prev) => {
          return {
            ...prev,
            cid1: true,
            cid2: true,
            sync_support: true,
            resource_id: true,
            description: true,
          };
        });
      }

      setSubmitted(true);
    },
    [formInfo, checkSupportCid, file],
  );

  /** Efeito para visão do componente abrir no topo da tela */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /** Efeito quando todos os campos estão válidos */
  useEffect(() => {
    const formatForm = (form: Step6Form) => {
      const requestForm: any = Object.assign({}, form);

      requestForm.cid = [];

      if (requestForm.cid1) {
        requestForm.cid.push(requestForm.cid1);
      }
      if (requestForm.cid2) {
        requestForm.cid.push(requestForm.cid2);
      }

      if (!requestForm.sync_support) requestForm.sync_support = false;

      delete requestForm.cid1;
      delete requestForm.cid2;

      return requestForm;
    };

    const uploadFile = async () => {
      try {
        const formData = new FormData();

        if (file) formData.append('medical_report', file);

        const {
          data: { success },
        } = await randomApi.post<UploadFileResponse>(
          '/assistive_technologies/document',
          formData,
          {
            headers: headers(candidateToken),
            timeout: 30000,
          },
        );

        if (!success) {
          updateSpinner(false);
          navigate('/erro-processo-seletivo');
          return;
        }
      } catch {
        updateSpinner(false);
        navigate('/erro-processo-seletivo');
        return;
      }
    };

    const updateAssistiveTechnology = async () => {
      try {
        const {
          data: { success },
        } = await randomApi.post<UpdateAssistiveTechnologyResponse>(
          `/assistive_technologies/accessibility`,
          formatForm(formInfo),
          {
            headers: headers(candidateToken),
            timeout: 30000,
          },
        );

        updateSpinner(false);

        if (!success) {
          navigate('/erro-processo-seletivo');
          return;
        }

        updateStep(6);
      } catch {
        updateSpinner(false);
        navigate('/erro-processo-seletivo');
        return;
      }
    };

    const effect = async () => {
      if (submitted) {
        setErrorAlert(false);
        let minOneFalse = false;
        for (const val in formValid) {
          if (!formValid[val as keyof Step6FormValid]) minOneFalse = true;
        }

        if (formInfo.has_disability && !fileValid) minOneFalse = true;

        setSubmitted(false);

        if (!minOneFalse) {
          updateSpinner(true);
          updateForm((prev: RegisterForm) => {
            return Object.assign(prev, formInfo);
          });

          if (file) {
            await uploadFile();
          }

          await updateAssistiveTechnology();
        } else {
          window.scrollTo(0, 0);
          setErrorAlert(true);
        }
      }
    };

    effect();
  }, [
    formInfo,
    formValid,
    submitted,
    updateSpinner,
    navigate,
    candidateToken,
    file,
    fileValid,
    updateStep,
    updateForm,
  ]);

  return (
    <MainForm onSubmit={handleConfirmButton}>
      {errorAlert ? (
        <StyledInputAlert icon={<ErrorIcon fontSize="inherit" />}>
          Por favor, revise os valores dos campos
        </StyledInputAlert>
      ) : (
        <></>
      )}
      <StyledLabel>Você possui alguma deficiência? *</StyledLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={formInfo.has_disability}
        onChange={({ target: { value } }) =>
          setFormInfo((prev) => {
            setFormValid({ ...formValid, has_disability: true });
            return { ...prev, has_disability: value === 'true' };
          })
        }
      >
        <GreenFormLabel value={true} control={<Radio />} label="Sim" />
        <GreenFormLabel value={false} control={<Radio />} label="Não" />
      </RadioGroup>
      {formValid.has_disability ? (
        <></>
      ) : (
        <StyledErrorLabel id="deficiencyError">
          É necessário selecionar uma opção
        </StyledErrorLabel>
      )}
      {formInfo.has_disability ? (
        <>
          <NewStyledLabel>Informe os CIDs abaixo: *</NewStyledLabel>
          <InputTextField
            label="CID 1 *"
            focused
            placeholder="CID (Obrigatório)"
            type="text"
            className="inputField"
            onChange={({ target: { value } }) =>
              setFormInfo((prev) => {
                setFormValid({ ...formValid, cid1: true });
                return { ...prev, cid1: value };
              })
            }
            inputProps={{ maxLength: 15 }}
            sx={
              formValid.cid1
                ? {
                    '& .MuiOutlinedInput-root': {
                      color: 'white !important',
                      '&.Mui-focused fieldset': {
                        borderColor: '#7ddd00',
                      },
                      '&:hover fieldset': {
                        borderColor: '#7ddd00',
                      },
                      '& fieldset': {
                        borderColor: '#7ddd00',
                      },
                    },
                  }
                : {
                    '& .MuiOutlinedInput-root': {
                      color: 'white !important',
                      '&.Mui-focused fieldset': {
                        borderColor: 'red',
                      },
                      '&:hover fieldset': {
                        borderColor: 'red',
                      },
                      '& fieldset': {
                        borderColor: 'red',
                      },
                    },
                  }
            }
            value={formInfo.cid1}
          />
          {formValid.cid1 ? (
            <></>
          ) : (
            <StyledErrorLabel id="cid1Error">
              CID inválido ou com tamanho inválido
            </StyledErrorLabel>
          )}
          <InputTextField
            label="CID 2"
            focused
            placeholder="CID (Opcional)"
            type="text"
            className="inputField"
            onChange={({ target: { value } }) =>
              setFormInfo((prev) => {
                setFormValid({ ...formValid, cid2: true });
                return { ...prev, cid2: value };
              })
            }
            inputProps={{ maxLength: 15 }}
            sx={
              formValid.cid2
                ? {
                    '& .MuiOutlinedInput-root': {
                      color: 'white !important',
                      '&.Mui-focused fieldset': {
                        borderColor: '#7ddd00',
                      },
                      '&:hover fieldset': {
                        borderColor: '#7ddd00',
                      },
                      '& fieldset': {
                        borderColor: '#7ddd00',
                      },
                    },
                  }
                : {
                    '& .MuiOutlinedInput-root': {
                      color: 'white !important',
                      '&.Mui-focused fieldset': {
                        borderColor: 'red',
                      },
                      '&:hover fieldset': {
                        borderColor: 'red',
                      },
                      '& fieldset': {
                        borderColor: 'red',
                      },
                    },
                  }
            }
            value={formInfo.cid2}
          />
          {formValid.cid2 ? (
            <></>
          ) : (
            <StyledErrorLabel id="cid2Error">
              CID inválido ou com tamanho inválido
            </StyledErrorLabel>
          )}
          <SecondStyledLabel>
            Upload de comprovante ou relatório médico: *
          </SecondStyledLabel>
          <Button
            variant="contained"
            component="label"
            startIcon={<StyledFileUploadIcon />}
            sx={
              fileValid
                ? {
                    backgroundColor: '#7ddd00',
                    color: 'black',
                    fontWeight: '700',
                    height: '50px',
                    '&:hover': {
                      backgroundColor: '#549d02',
                    },
                    margin: '8px 0px',
                    marginTop: '6px',
                    textAlign: 'center',
                    padding: '0 3px',
                    width: 'min(500px, 90vw)',
                    '@media screen and (max-width: 700px)': {
                      fontSize: '9px',
                    },
                  }
                : {
                    backgroundColor: '#da1e1e',
                    color: 'black',
                    fontWeight: '700',
                    height: '50px',
                    '&:hover': {
                      backgroundColor: '#dd511e',
                    },
                    margin: '8px 0px',
                    marginTop: '6px',
                    textAlign: 'center',
                    padding: '0 3px',
                    width: 'min(500px, 90vw)',
                    '@media screen and (max-width: 700px)': {
                      fontSize: '9px',
                    },
                  }
            }
          >
            {file ? file.name : 'Anexo de comprovante/relatório médico *'}
            <input
              onChange={({ target: { files } }) => {
                setFileValid(true);
                setFile(files ? files[0] : null);
              }}
              accept="image/png, image/jpeg, application/pdf"
              type="file"
              hidden
              name="inputField"
              value={file?.filename}
            />
          </Button>
          {fileValid ? (
            <GreenLabel id="docImgError">
              Arquivo .png, .jpg, .jpeg ou .pdf de até 4 mb.
            </GreenLabel>
          ) : (
            <StyledErrorLabel id="medicalReportError">
              É necessário um arquivo .png, .jpg, .jpeg ou .pdf de até 4 mb.
            </StyledErrorLabel>
          )}
          {checkSupportCid() ? (
            <>
              <ThirdStyledLabel>
                Você gostaria de receber atendimento síncrono, por vídeo, para
                dúvidas sobre a inscrição ou processo seletivo? *
              </ThirdStyledLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={formInfo.sync_support}
                onChange={({ target: { value } }) =>
                  setFormInfo((prev) => {
                    setFormValid({ ...formValid, sync_support: true });
                    return { ...prev, sync_support: value === 'true' };
                  })
                }
              >
                <GreenFormLabel value={true} control={<Radio />} label="Sim" />
                <GreenFormLabel value={false} control={<Radio />} label="Não" />
              </RadioGroup>
              {formValid.sync_support ? (
                <></>
              ) : (
                <StyledErrorLabel id="treatmentError">
                  É necessário selecionar uma opção
                </StyledErrorLabel>
              )}
            </>
          ) : (
            <></>
          )}
          <ThirdStyledLabel>
            Você precisa de algum recurso de acessibilidade ou tecnologia
            assistiva durante o processo avaliativo? *
          </ThirdStyledLabel>
          <StyledSelect
            value={formInfo.resource_id}
            onChange={({ target: { value } }) =>
              setFormInfo((prev) => {
                setFormValid({ ...formValid, resource_id: true });
                return {
                  ...prev,
                  resource_id: value as number,
                };
              })
            }
            sx={
              formValid.resource_id
                ? {
                    marginTop: '8px',
                    '.MuiSelect-select': {
                      color: 'white !important',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                      borderWidth: '2px !important',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                      borderWidth: '2px !important',
                    },
                  }
                : {
                    marginTop: '8px',
                    '.MuiSelect-select': {
                      color: 'white',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red !important',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red',
                      borderWidth: '2px !important',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red',
                      borderWidth: '2px !important',
                    },
                  }
            }
          >
            <StyledMenuItem disabled value={0}>
              <em>Selecione</em>
            </StyledMenuItem>
            {assistiveTechnologies.map(
              ({ id_tecnologia_assistiva, tecnologia_assistiva }) => (
                <StyledMenuItem
                  value={id_tecnologia_assistiva}
                  key={id_tecnologia_assistiva}
                >
                  {tecnologia_assistiva}
                </StyledMenuItem>
              ),
            )}
          </StyledSelect>
          {formValid.resource_id ? (
            <></>
          ) : (
            <StyledErrorLabel id="resourceError">
              É necessário selecionar uma opção
            </StyledErrorLabel>
          )}
          <ThirdStyledLabel>
            Campo aberto para especificar sua necessidade de assistividade
            (opcional - máx. 200 caracteres):
          </ThirdStyledLabel>
          <InputTextField
            placeholder="Especificação..."
            multiline
            maxRows={4}
            type="text"
            inputProps={{ maxLength: 200 }}
            className="inputField"
            onChange={({ target: { value } }) =>
              setFormInfo((prev) => {
                setFormValid({ ...formValid, description: true });
                return { ...prev, description: value };
              })
            }
            sx={
              formValid.description
                ? {
                    '& .MuiOutlinedInput-root': {
                      color: 'white !important',
                      '&.Mui-focused fieldset': {
                        borderColor: '#7ddd00',
                      },
                      '&:hover fieldset': {
                        borderColor: '#7ddd00',
                      },
                      '& fieldset': {
                        borderColor: '#7ddd00',
                      },
                    },
                  }
                : {
                    '& .MuiOutlinedInput-root': {
                      color: 'white !important',
                      '&.Mui-focused fieldset': {
                        borderColor: 'red',
                      },
                      '&:hover fieldset': {
                        borderColor: 'red',
                      },
                      '& fieldset': {
                        borderColor: 'red',
                      },
                    },
                  }
            }
            value={formInfo.description}
          />
          {formValid.description ? (
            <></>
          ) : (
            <StyledErrorLabel id="assistivityTextError">
              O texto deve possuir no máximo 200 caracteres.
            </StyledErrorLabel>
          )}
        </>
      ) : (
        <></>
      )}
      <ButtonsDiv>
        <ConfirmButton type="submit">AVANÇAR</ConfirmButton>
        <BackButton type="button" onClick={handleBackButton}>
          VOLTAR
        </BackButton>
      </ButtonsDiv>
    </MainForm>
  );
};

export default Step6;
