import { Button } from '@mui/material';
import styled from 'styled-components';

const BackButton = styled(Button)`
  background-color: #7ddd00 !important;
  color: black !important;
  font-weight: 700 !important;
  width: min(150px, 90vw);
  margin-right: 15px !important;
  /* padding-right: 30px !important; */
`;

export default BackButton;
