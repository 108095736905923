import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import BackButton from '../../components/back-button';
import ButtonsDiv from '../../components/buttons-div';
import ConfirmButton from '../../components/confirm-button';
import ErrorLabel from '../../components/error-label';
import InputTextField from '../../components/input-text-field';
import MainForm from '../../components/main-form';
import { addressNumberMask, CpfMask } from '../../components/masks';
import {
  birthdayMask,
  cepMask,
  highSchoolCompletionMask,
} from '../../components/masks';
import getCep from '../../shared/cep';
import { headers } from '../../shared/config';
import { randomApi } from '../../shared/random';
import {
  validateAddressNumber,
  validateBirthday,
  validateCep,
  validateCpf,
  validateEmail,
  validateImg,
  validateMonthYear,
  validateName,
  validateRg,
  validateSelect,
  validateStringSelect,
} from '../../shared/validators';
import { RegisterForm } from '../Register';
import ErrorIcon from '@mui/icons-material/Error';

/** Definição de componentes */
const StyledMenuItem = styled(MenuItem)`
  max-width: 100% !important;
  overflow: auto !important;
  font-size: 14px !important;
`;

const StyledPInfo = styled.p`
  text-align: center;
  margin-bottom: 20px;
`;

const StyledErrorLabel = styled(ErrorLabel)`
  margin-top: -4px;
  margin-bottom: 10px;
`;

const GreenLabel = styled(StyledErrorLabel)`
  margin-top: -4px;
  margin-bottom: 10px;
  color: #7ddd00;
`;

const StyledSelect = styled(Select)`
  margin-bottom: 8px;
  max-width: min(500px, 95vw) !important;
`;

export const StyledInputAlert = styled(Alert)`
  display: flex !important;
  align-items: center !important;
  width: min(500px, 86vw) !important;
  /* margin-left: -17px !important; */
  background-color: rgb(211, 92, 98) !important;
  font-family: 'Radio Canada', sans-serif !important;
  color: white !important;
  font-size: 13px !important;
  /* margin: 0 auto !important; */
  margin-top: -5px !important;
  margin-bottom: 15px !important;
  height: 45px !important;
  margin-left: -0.6rem !important;

  svg {
    color: white !important;
    margin-top: -4px !important;
  }

  @media only screen and (min-width: 1200px) {
    height: 35px !important;
    font-size: 15px !important;
    margin-left: -16px !important;
  }

  div {
    margin-top: 2px;
  }
`;

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'rgb(42,42,42)',
  p: 4,
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  fontSize: '26px',
  flexDirection: 'column',
  justifyContent: 'center',
  fontWeight: 'regular !important',
  textAlign: 'center',
  height: '225px',
  width: 'min(450px, 95vw) !important',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'auto',
  padding: '40px 0px',
};

const StyledP = styled.p`
  width: 85%;
  font-size: 20px;
  margin-bottom: 40px;
`;

const StyledFileUploadIcon = styled(FileUploadIcon)`
  @media (max-width: 700px) {
    font-size: 14px !important;
  }
`;

/** Definição de interfaces */
interface IProps {
  candidateToken: string;
  updateStep: Function;
  updateSpinner: Function;
  updateForm: Function;
  form: RegisterForm;
  genres: GenreResponse[];
  setGenres: Function;
  states: StateResponse[];
  setStates: Function;
  cities: CityResponse[];
  setCities: Function;
  formations: FormationResponse[];
  setFormations: Function;
  kinships: KinshipResponse[];
  setKinships: Function;
}

interface IResponsible {
  name: string;
  rg: string;
  cpf: string;
  email: string;
  kinship_id: number;
}

interface IResponsibleValid {
  name: boolean;
  rg: boolean;
  cpf: boolean;
  email: boolean;
  kinship_id: boolean;
}

interface Step3Form {
  rg: string;
  birthday: string;
  nationality: string;
  genre_id: number;
  address: {
    cep: string;
    address: string;
    number: string;
    complement?: string;
    neighborhood: string;
    city_id: number;
    state: string;
  };
  formation: {
    formation_id: number;
    high_school_graduation_date: string;
    high_school_type: string;
  };
}

interface Step3FormValid {
  rg: boolean;
  birthday: boolean | number;
  nationality: boolean;
  genre_id: boolean;
  address: {
    cep: boolean;
    address: boolean;
    number: boolean;
    complement?: boolean;
    neighborhood: boolean;
    city_id: boolean;
    state: boolean;
  };
  formation: {
    formation_id: boolean;
    high_school_graduation_date: boolean | Date;
    high_school_type: boolean;
  };
}

export interface GenreResponse {
  id_identidade_genero: number;
  identidade_genero: string;
  informacao_adicional: string;
}

export interface StateResponse {
  estado: string;
  id_estado: number;
  sigla_estado: string;
}

export interface CityResponse {
  cidade: string;
  id_cidade: number;
}

export interface FormationResponse {
  descricao: string;
  id_formacao: number;
}

export interface KinshipResponse {
  id_parentesco: number;
  parentesco: string;
}

export interface GetCitiesResponse {
  success: boolean;
  response: CityResponse[] | null;
}

interface UploadFileResponse {
  success: boolean;
}

interface UpdatePersonalDataResponse {
  success: boolean;
}

export interface GetGenreIdentitiesResponse {
  success: boolean;
  response: GenreResponse[] | null;
}

export interface GetStatesResponse {
  success: boolean;
  response: StateResponse[] | null;
}

export interface GetFormationsResponse {
  success: boolean;
  response: FormationResponse[] | null;
}

export interface GetKinshipsResponse {
  success: boolean;
  response: KinshipResponse[] | null;
}

const Step3 = (props: IProps) => {
  /** Variaveis iniciais */
  const navigate = useNavigate();

  const {
    candidateToken,
    updateStep,
    updateSpinner,
    updateForm,
    form,
    genres,
    setGenres,
    states,
    setStates,
    cities,
    setCities,
    formations,
    setFormations,
    kinships,
    setKinships,
  } = props;

  const highSchoolTypes = useMemo(
    () => [
      { id_tipo: 1, tipo: 'Pública' },
      { id_tipo: 2, tipo: 'Privada' },
    ],
    [],
  );

  /** Estados */
  const [formInfo, setFormInfo] = useState<Step3Form>({
    rg: '',
    birthday: '',
    nationality: 'Brasileira',
    genre_id: 0,
    address: {
      cep: '',
      address: '',
      number: '',
      neighborhood: '',
      city_id: 0,
      state: '0',
    },
    formation: {
      formation_id: 0,
      high_school_graduation_date: '',
      high_school_type: '0',
    },
  });

  const [formValid, setFormValid] = useState<Step3FormValid>({
    rg: true,
    birthday: true,
    nationality: true,
    genre_id: true,
    address: {
      cep: true,
      address: true,
      number: true,
      complement: true,
      neighborhood: true,
      city_id: true,
      state: true,
    },
    formation: {
      formation_id: true,
      high_school_graduation_date: true,
      high_school_type: true,
    },
  });

  const [formUAInfo, setFormUAInfo] = useState<IResponsible>({
    name: '',
    rg: '',
    cpf: '',
    email: '',
    kinship_id: 0,
  });

  const [formUAValid, setFormUAValid] = useState<IResponsibleValid>({
    name: true,
    rg: true,
    cpf: true,
    email: true,
    kinship_id: true,
  });

  const [file, setFile] = useState<any>(null);

  const [fileValid, setFileValid] = useState<boolean>(true);

  const [firstIteration, setFirstIteration] = useState(true);

  const [stateFirstIteration, setStateFirstIteration] = useState(true);

  const [submitted, setSubmitted] = useState<boolean>(false);

  const [submitted2, setSubmitted2] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(true);

  const [firstIteration2, setFirstIteration2] = useState<boolean>(true);

  const [cepObject, setCepObject] = useState<any>(false);

  const [alerta, setAlerta] = useState<number>(0);

  const [underAge, setUnderAge] = useState<boolean>(false);

  const [errorAlert, setErrorAlert] = useState<boolean>(false);

  const [errorAlert2, setErrorAlert2] = useState<boolean>(false);

  /** Efeito para recuperar valores salvos no form geral */
  useEffect(() => {
    if (firstIteration) {
      Object.keys(formInfo).map(async (key) => {
        if (form.hasOwnProperty(key)) {
          if (form[key as keyof RegisterForm]) {
            setFormInfo(() => {
              return Object.assign(formInfo, {
                [key]: form[key as keyof RegisterForm],
              });
            });
          }
        }
        return null;
      });
      setFirstIteration(false);
    }
  }, [firstIteration, formInfo, form]);

  /** Efeito para recuperar valores salvos no form geral (segundo) */
  useEffect(() => {
    if (firstIteration2) {
      Object.keys(formUAInfo).map((key) => {
        if (form.responsible.hasOwnProperty(key)) {
          if ((form.responsible as any)[key as keyof RegisterForm]) {
            setFormUAInfo(() => {
              return Object.assign(formUAInfo, {
                [key]: (form.responsible as any)[key as keyof RegisterForm],
              });
            });
          }
        }
        return null;
      });
      setFirstIteration2(false);
    }
  }, [formUAInfo, form, firstIteration2]);

  /** Função que recupera cidades a partir de um estado */
  const getCities = useCallback(
    async (stateID: number) => {
      updateSpinner(true);
      try {
        const {
          data: { success, response },
        } = await randomApi.get<GetCitiesResponse>(
          `/cities/?state_id=${stateID}`,
          {
            headers: headers(candidateToken),
            timeout: 30000,
          },
        );

        updateSpinner(false);

        if (!success) {
          navigate('/erro-processo-seletivo');
          return;
        }

        setCities(response as CityResponse[]);
        return response;
      } catch {
        updateSpinner(false);
        navigate('/erro-processo-seletivo');
        return;
      }
    },
    [updateSpinner, navigate, candidateToken, setCities],
  );

  /**Efeito que os valores dos selects */
  useEffect(() => {
    const getGenderIdentities = async () => {
      try {
        const {
          data: { success, response },
        } = await randomApi.get<GetGenreIdentitiesResponse>(
          '/genre_identities/',
          {
            headers: headers(candidateToken),
            timeout: 30000,
          },
        );

        if (!success) {
          updateSpinner(false);
          navigate('/erro-processo-seletivo');
          return;
        }

        setGenres(response as GenreResponse[]);
      } catch {
        updateSpinner(false);
        navigate('/erro-processo-seletivo');
        return;
      }
    };

    const effect = async () => {
      updateSpinner(true);
      if (genres.length === 0) await getGenderIdentities();
      updateSpinner(false);
    };

    effect();
  }, [updateSpinner, navigate, candidateToken, genres, setGenres]);

  /**Efeito que os valores dos selects */
  useEffect(() => {
    const getStates = async () => {
      try {
        const {
          data: { success, response },
        } = await randomApi.get<GetStatesResponse>('/states/', {
          headers: headers(candidateToken),
          timeout: 30000,
        });

        if (!success) {
          updateSpinner(false);
          navigate('/erro-processo-seletivo');
          return;
        }

        if (
          formInfo.address.state &&
          formInfo.address.state !== '0' &&
          response
        ) {
          await getCities(
            response.find(
              (state: StateResponse) => state.estado === formInfo.address.state,
            )?.id_estado as number,
          );
        }

        setStates(response as StateResponse[]);
      } catch {
        updateSpinner(false);
        navigate('/erro-processo-seletivo');
        return;
      }
    };

    const effect = async () => {
      updateSpinner(true);
      if (states.length === 0 && stateFirstIteration) {
        setStateFirstIteration(false);
        await getStates();
      }
      updateSpinner(false);
    };

    effect();
  }, [
    updateSpinner,
    navigate,
    candidateToken,
    states,
    setStates,
    formInfo.address.state,
    getCities,
    stateFirstIteration,
  ]);

  /**Efeito que os valores dos selects */
  useEffect(() => {
    const getFormations = async () => {
      try {
        const {
          data: { success, response },
        } = await randomApi.get<GetFormationsResponse>('/formations/', {
          headers: headers(candidateToken),
          timeout: 30000,
        });

        if (!success) {
          updateSpinner(false);
          navigate('/erro-processo-seletivo');
          return;
        }

        setFormations(response as FormationResponse[]);
      } catch {
        updateSpinner(false);
        navigate('/erro-processo-seletivo');
        return;
      }
    };

    const effect = async () => {
      updateSpinner(true);
      if (formations.length === 0) await getFormations();
      updateSpinner(false);
    };

    effect();
  }, [updateSpinner, navigate, candidateToken, formations, setFormations]);

  /**Efeito que os valores dos selects */
  useEffect(() => {
    const getKinships = async () => {
      try {
        const {
          data: { success, response },
        } = await randomApi.get<GetKinshipsResponse>('/kinships/', {
          headers: headers(candidateToken),
          timeout: 30000,
        });

        if (!success) {
          updateSpinner(false);
          navigate('/erro-processo-seletivo');
          return;
        }

        setKinships(response as KinshipResponse[]);
      } catch {
        updateSpinner(false);
        navigate('/erro-processo-seletivo');
        return;
      }
    };

    const effect = async () => {
      updateSpinner(true);
      if (kinships.length === 0) await getKinships();
      updateSpinner(false);
    };

    effect();
  }, [updateSpinner, navigate, candidateToken, kinships, setKinships]);

  /** Função para controlar o fechamento do modal */
  const handleClose = () => setOpen(false);

  /** Função para controlar o clique de fechamento do modal */
  const handleModalButton = () => setOpen(false);

  /** Função do botão de confirmação */
  const handleConfirmButton = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      setFormValid((prev) => {
        return { ...prev, rg: validateRg(formInfo.rg) };
      });

      file ? setFileValid(validateImg(file)) : setFileValid(false);

      setFormValid((prev) => {
        return { ...prev, birthday: validateBirthday(formInfo.birthday) };
      });

      setFormValid((prev) => {
        return { ...prev, genre_id: validateSelect(formInfo.genre_id) };
      });

      setFormValid((prev) => {
        return {
          ...prev,
          address: { ...prev.address, cep: validateCep(formInfo.address.cep) },
        };
      });

      setFormValid((prev) => {
        return {
          ...prev,
          address: {
            ...prev.address,
            number: validateAddressNumber(formInfo.address.number),
          },
        };
      });

      setFormValid((prev) => {
        return {
          ...prev,
          address: {
            ...prev.address,
            state: validateStringSelect(formInfo.address.state),
          },
        };
      });

      setFormValid((prev) => {
        return {
          ...prev,
          address: {
            ...prev.address,
            city_id: validateSelect(formInfo.address.city_id),
          },
        };
      });

      setFormValid((prev) => {
        return {
          ...prev,
          formation: {
            ...prev.formation,
            formation_id: validateSelect(formInfo.formation.formation_id),
          },
        };
      });

      setFormValid((prev) => {
        return {
          ...prev,
          formation: {
            ...prev.formation,
            high_school_graduation_date: validateMonthYear(
              formInfo.formation.high_school_graduation_date,
            ),
          },
        };
      });

      setFormValid((prev) => {
        return {
          ...prev,
          formation: {
            ...prev.formation,
            high_school_type: validateStringSelect(
              formInfo.formation.high_school_type,
            ),
          },
        };
      });

      setSubmitted(true);
    },
    [formInfo, file],
  );

  /** Função que realiza o submit final */
  const handleSubmitForm = useCallback(
    async (form: Step3Form) => {
      const formatForm = (form: Step3Form) => {
        form.birthday = `${form.birthday.split('/')[2]}-${
          form.birthday.split('/')[1]
        }-${form.birthday.split('/')[0]}`;

        form.formation.high_school_graduation_date = `${
          form.formation.high_school_graduation_date.split('/')[1]
        }-${form.formation.high_school_graduation_date.split('/')[0]}-01`;

        // form.formation.high_school_type =
        //   highSchoolTypes.find(
        //     (type) => type.id_tipo === form.formation.high_school_type,
        //   )?.tipo || '';

        return form;
      };

      const uploadFile = async () => {
        try {
          const formData = new FormData();

          if (file) formData.append('personal_data_document', file);

          const {
            data: { success },
          } = await randomApi.post<UploadFileResponse>(
            '/personal_data/document',
            formData,
            {
              headers: headers(candidateToken),
              timeout: 30000,
            },
          );

          if (!success) {
            updateSpinner(false);
            navigate('/erro-processo-seletivo');
            return;
          }
        } catch {
          updateSpinner(false);
          navigate('/erro-processo-seletivo');
          return;
        }
      };

      const updatePersonalData = async () => {
        try {
          const {
            data: { success },
          } = await randomApi.post<UpdatePersonalDataResponse>(
            `/personal_data/`,
            formatForm(form),
            {
              headers: headers(candidateToken),
              timeout: 30000,
            },
          );

          updateSpinner(false);

          if (!success) {
            navigate('/erro-processo-seletivo');
            return;
          }

          form.birthday = `${form.birthday.split('-')[2]}/${
            form.birthday.split('-')[1]
          }/${form.birthday.split('-')[0]}`;
          form.formation.high_school_graduation_date = `${
            form.formation.high_school_graduation_date.split('-')[1]
          }/${form.formation.high_school_graduation_date.split('-')[0]}`;

          updateForm((prev: RegisterForm) => {
            return Object.assign(prev, form);
          });

          updateStep(3);
        } catch {
          updateSpinner(false);
          navigate('/erro-processo-seletivo');
          return;
        }
      };

      updateSpinner(true);
      await uploadFile();
      await updatePersonalData();
    },
    [candidateToken, file, navigate, updateSpinner, updateStep, updateForm],
  );

  /** Efeito para visão do componente abrir no topo da tela */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /** Efeito de submit do primeiro formulário */
  useEffect(() => {
    const effect = async () => {
      if (submitted) {
        setErrorAlert(false);
        let minOneFalse = false;

        for (const val in formValid) {
          if (!formValid[val as keyof Step3FormValid]) minOneFalse = true;
        }
        for (const val in formValid.address) {
          if (!formValid.address[val as keyof Step3FormValid['address']])
            minOneFalse = true;
        }
        for (const val in formValid.formation) {
          if (!formValid.formation[val as keyof Step3FormValid['formation']])
            minOneFalse = true;
        }
        if (!fileValid) minOneFalse = true;

        setSubmitted(false);

        if (!minOneFalse) {
          /* Verificar data de conclusao do EM */
          const minDate = new Date(Date.UTC(2024, 0));

          if (formValid.formation.high_school_graduation_date >= minDate) {
            setAlerta(1);
            setOpen(true);
          }

          /* Verificar se menor de idade */
          if (formValid.birthday !== 18) {
            setUnderAge(true);
          } else {
            handleSubmitForm(formInfo);
          }
        } else {
          window.scrollTo(0, 0);
          setErrorAlert(true);
        }
      }
    };

    effect();
  }, [
    cities,
    formations,
    formInfo,
    formValid,
    genres,
    submitted,
    navigate,
    updateSpinner,
    fileValid,
    handleSubmitForm,
  ]);

  /** Efeito de submit do segundo formulário */
  useEffect(() => {
    const effect = async () => {
      if (submitted2) {
        setErrorAlert2(false);
        let minOneFalse = false;
        for (const val in formUAValid) {
          if (!formUAValid[val as keyof IResponsibleValid]) minOneFalse = true;
        }

        setSubmitted2(false);

        if (!minOneFalse) {
          const formWithUA = Object.assign(formInfo, {
            responsible: formUAInfo,
          });
          handleSubmitForm(formWithUA);
        } else {
          window.scrollTo(0, 0);
          setErrorAlert2(true);
        }
      }
    };

    effect();
  }, [
    navigate,
    updateSpinner,
    formInfo,
    formUAInfo,
    formUAValid,
    submitted2,
    handleSubmitForm,
  ]);

  /** Função do segundo botão de confirmação */
  const handleUnderAgeSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setFormUAValid((prev: any) => {
        return { ...prev, kinship_id: validateSelect(formUAInfo.kinship_id) };
      });

      setFormUAValid((prev: any) => {
        return { ...prev, name: validateName(formUAInfo.name) };
      });

      setFormUAValid((prev: any) => {
        return {
          ...prev,
          rg: validateRg(formUAInfo.rg),
        };
      });

      setFormUAValid((prev: any) => {
        return { ...prev, cpf: validateCpf(formUAInfo.cpf) };
      });

      setFormUAValid((prev: any) => {
        return { ...prev, email: validateEmail(formUAInfo.email) };
      });

      setSubmitted2(true);
    },
    [formUAInfo],
  );

  /** Função para controlar o botão de retorno do modal de under age */
  const handleUnderAgeBackButton = () => {
    setUnderAge(false);
  };

  return !underAge ? (
    <MainForm onSubmit={handleConfirmButton}>
      {errorAlert ? (
        <StyledInputAlert icon={<ErrorIcon fontSize="inherit" />}>
          Por favor, revise os valores dos campos
        </StyledInputAlert>
      ) : (
        <></>
      )}
      <InputTextField
        focused
        placeholder="00000000"
        required
        label="RG (somente números)"
        type="text"
        className="inputField"
        onChange={({ target: { value } }) =>
          setFormInfo((prev) => {
            setFormValid({ ...formValid, rg: true });
            return { ...prev, rg: value };
          })
        }
        InputProps={{
          inputComponent: addressNumberMask as any,
        }}
        inputProps={{ maxLength: 20 }}
        sx={
          formValid.rg
            ? {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '& fieldset': {
                    borderColor: '#7ddd00',
                  },
                },
              }
            : {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '& fieldset': {
                    borderColor: 'red',
                  },
                },
              }
        }
        value={formInfo.rg}
      />
      {formValid.rg ? (
        <></>
      ) : (
        <StyledErrorLabel id="rgError">RG inválido</StyledErrorLabel>
      )}
      <Button
        variant="contained"
        component="label"
        startIcon={<StyledFileUploadIcon />}
        sx={
          fileValid
            ? {
                backgroundColor: '#7ddd00',
                color: 'black',
                fontWeight: '700',
                height: '50px',
                '&:hover': {
                  backgroundColor: '#549d02',
                },
                margin: '8px 0px',
                marginTop: '6px',
                textAlign: 'center',
                padding: '0 3px',
                '@media screen and (max-width: 700px)': {
                  fontSize: '9px',
                },
              }
            : {
                backgroundColor: 'rgb(211, 92, 98)',
                color: 'black',
                fontWeight: '700',
                height: '50px',
                '&:hover': {
                  backgroundColor: 'rgb(211, 92, 98)',
                  opacity: 0.9,
                },
                margin: '8px 0px',
                marginTop: '6px',
                textAlign: 'center',
                padding: '0 3px',
                '@media screen and (max-width: 700px)': {
                  fontSize: '9px',
                },
              }
        }
      >
        {file ? file.name : 'Cópia/Foto do documento (RG e CPF) *'}
        <input
          onChange={({ target: { files } }) => {
            setFileValid(true);
            setFile(files ? files[0] : null);
          }}
          accept="image/png, image/jpeg, application/pdf"
          type="file"
          hidden
          name="inputField"
          value={file?.filename}
        />
      </Button>
      {fileValid ? (
        <GreenLabel id="docImgError">
          Arquivo .png, .jpg, .jpeg ou .pdf de até 4MB.
        </GreenLabel>
      ) : (
        <StyledErrorLabel id="docImgError">
          É necessário um arquivo .png, .jpg, .jpeg ou .pdf de até 4MB.
        </StyledErrorLabel>
      )}
      <InputTextField
        focused
        placeholder="DD/MM/AAAA"
        required
        label="Data de nascimento"
        className="inputField"
        onChange={({ target: { value } }) =>
          setFormInfo((prev) => {
            setFormValid({ ...formValid, birthday: true });
            return { ...prev, birthday: value as string };
          })
        }
        InputProps={{
          inputComponent: birthdayMask as any,
        }}
        sx={
          formValid.birthday
            ? {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '& fieldset': {
                    borderColor: '#7ddd00',
                  },
                },
              }
            : {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '& fieldset': {
                    borderColor: 'red',
                  },
                },
              }
        }
        value={formInfo.birthday}
      />
      {formValid.birthday ? (
        <></>
      ) : (
        <StyledErrorLabel id="birthdayError">Data inválida</StyledErrorLabel>
      )}
      <InputTextField
        placeholder=""
        required
        label="Nacionalidade"
        className="inputField"
        InputProps={{
          readOnly: true,
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            color: 'rgba(255,255,255,0.5) !important',
            '&.Mui-focused fieldset': {
              borderColor: '#7ddd00',
            },
            '&.Mui-disabled fieldset': {
              borderColor: '#7ddd00',
            },
            '&:hover fieldset': {
              borderColor: '#7ddd00',
            },
            '& fieldset': {
              borderColor: '#7ddd00',
            },
          },
          '& .MuiInputLabel-root': {
            color: 'white !important',
          },
        }}
        value={formInfo.nationality}
      />
      <FormControl>
        <InputLabel
          sx={{ color: 'white !important', marginTop: '8px' }}
          id="genre-select-label"
        >
          Gênero *
        </InputLabel>
        <StyledSelect
          labelId="genre-select-label"
          value={formInfo.genre_id}
          label="Gênero *"
          onChange={({ target: { value } }) =>
            setFormInfo((prev) => {
              setFormValid({ ...formValid, genre_id: true });
              return { ...prev, genre_id: value as number };
            })
          }
          sx={
            formValid.genre_id
              ? {
                  marginTop: '8px',
                  '.MuiSelect-select': {
                    color: 'white !important',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                    borderWidth: '2px !important',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                    borderWidth: '2px !important',
                  },
                }
              : {
                  marginTop: '8px',
                  '.MuiSelect-select': {
                    color: 'white',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red !important',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red',
                    borderWidth: '2px !important',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red',
                    borderWidth: '2px !important',
                  },
                }
          }
        >
          <StyledMenuItem disabled value={0}>
            <em>Selecione</em>
          </StyledMenuItem>
          {genres.map(
            ({
              id_identidade_genero,
              identidade_genero,
              informacao_adicional,
            }) => (
              <StyledMenuItem
                value={id_identidade_genero}
                key={id_identidade_genero}
              >
                {`${identidade_genero} ${
                  informacao_adicional ? `(${informacao_adicional})` : ''
                }`}
              </StyledMenuItem>
            ),
          )}
        </StyledSelect>
      </FormControl>
      {formValid.genre_id ? (
        <></>
      ) : (
        <StyledErrorLabel id="genreError">
          É necessário selecionar uma opção de gênero
        </StyledErrorLabel>
      )}
      <InputTextField
        focused
        placeholder="00000-000"
        required
        label="CEP"
        type="text"
        className="inputField"
        onChange={async ({ target: { value } }) => {
          if (validateCep(value) && !form.address.address) {
            updateSpinner(true);
            const momentCep = await getCep(value);
            updateSpinner(false);

            if (momentCep) {
              setCepObject(momentCep);
              setFormInfo((prev) => {
                setFormValid({
                  ...formValid,
                  address: { ...formValid.address, address: true },
                });
                return {
                  ...prev,
                  address: {
                    ...prev.address,
                    address:
                      cepObject.logradouro && cepObject.logradouro.length <= 80
                        ? cepObject.logradouro
                        : '',
                  },
                };
              });
              setFormInfo((prev) => {
                setFormValid({
                  ...formValid,
                  address: { ...formValid.address, complement: true },
                });
                return {
                  ...prev,
                  address: {
                    ...prev.address,
                    complement:
                      cepObject.complemento &&
                      cepObject.complemento.length <= 80
                        ? cepObject.complemento
                        : '',
                  },
                };
              });
              setFormInfo((prev) => {
                setFormValid({
                  ...formValid,
                  address: { ...formValid.address, neighborhood: true },
                });
                return {
                  ...prev,
                  address: {
                    ...prev.address,
                    neighborhood:
                      cepObject.bairro && cepObject.bairro.length <= 40
                        ? cepObject.bairro
                        : '',
                  },
                };
              });
              setFormInfo((prev) => {
                setFormValid({
                  ...formValid,
                  address: { ...formValid.address, state: true },
                });
                return {
                  ...prev,
                  address: {
                    ...prev.address,
                    state: cepObject.uf
                      ? states.find(
                          (state: any) => state.sigla_estado === cepObject.uf,
                        )?.estado || '0'
                      : '0',
                  },
                };
              });

              let localCities: any = null;
              if (cepObject.uf) {
                localCities = await getCities(
                  cepObject.uf
                    ? states.find(
                        (state) => state.sigla_estado === cepObject.uf,
                      )?.id_estado || 0
                    : 0,
                );
              }

              setFormInfo((prev) => {
                setFormValid({
                  ...formValid,
                  address: { ...formValid.address, city_id: true },
                });
                return {
                  ...prev,
                  address: {
                    ...prev.address,
                    city_id: cepObject.localidade
                      ? localCities.find(
                          (city: any) => city.cidade === cepObject.localidade,
                        )?.id_cidade || 0
                      : 0,
                  },
                };
              });
            }
          }
          setFormInfo((prev) => {
            setFormValid({
              ...formValid,
              address: { ...formValid.address, cep: true },
            });
            return {
              ...prev,
              address: {
                ...prev.address,
                cep: value,
              },
            };
          });
        }}
        InputProps={{
          inputComponent: cepMask as any,
        }}
        sx={
          formValid.address.cep
            ? {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '& fieldset': {
                    borderColor: '#7ddd00',
                  },
                },
              }
            : {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '& fieldset': {
                    borderColor: 'red',
                  },
                },
              }
        }
        value={formInfo.address.cep}
      />
      {formValid.address.cep ? (
        <></>
      ) : (
        <StyledErrorLabel id="cepError">CEP inválido</StyledErrorLabel>
      )}
      <InputTextField
        required
        focused
        placeholder="Rua Abc"
        label="Endereço"
        type="text"
        className="inputField"
        onChange={({ target: { value } }) =>
          setFormInfo((prev) => {
            setFormValid({
              ...formValid,
              address: { ...formValid.address, address: true },
            });
            return { ...prev, address: { ...prev.address, address: value } };
          })
        }
        inputProps={{ maxLength: 80 }}
        sx={
          formValid.address.address
            ? {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '& fieldset': {
                    borderColor: '#7ddd00',
                  },
                },
              }
            : {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '& fieldset': {
                    borderColor: 'red',
                  },
                },
              }
        }
        value={formInfo.address.address}
      />
      {formValid.address.address ? (
        <></>
      ) : (
        <StyledErrorLabel id="addressError">Endereço inválido</StyledErrorLabel>
      )}
      <InputTextField
        required
        focused
        placeholder="111"
        label="Número"
        type="text"
        className="inputField"
        onChange={({ target: { value } }) =>
          setFormInfo((prev) => {
            setFormValid({
              ...formValid,
              address: { ...formValid.address, number: true },
            });
            return { ...prev, address: { ...prev.address, number: value } };
          })
        }
        inputProps={{ maxLength: 10 }}
        InputProps={{
          inputComponent: addressNumberMask as any,
        }}
        sx={
          formValid.address.number
            ? {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '& fieldset': {
                    borderColor: '#7ddd00',
                  },
                },
              }
            : {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '& fieldset': {
                    borderColor: 'red',
                  },
                },
              }
        }
        value={formInfo.address.number}
      />
      {formValid.address.number ? (
        <></>
      ) : (
        <StyledErrorLabel id="numberError">Número inválido</StyledErrorLabel>
      )}
      <InputTextField
        focused
        label="Complemento"
        type="text"
        className="inputField"
        onChange={({ target: { value } }) =>
          setFormInfo((prev) => {
            setFormValid({
              ...formValid,
              address: { ...formValid.address, complement: true },
            });
            return { ...prev, address: { ...prev.address, complement: value } };
          })
        }
        inputProps={{ maxLength: 80 }}
        sx={
          formValid.address.complement
            ? {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '& fieldset': {
                    borderColor: '#7ddd00',
                  },
                },
              }
            : {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '& fieldset': {
                    borderColor: 'red',
                  },
                },
              }
        }
        value={formInfo.address.complement}
      />
      {formValid.address.complement ? (
        <></>
      ) : (
        <StyledErrorLabel id="complementError">
          Complemento inválido
        </StyledErrorLabel>
      )}
      <InputTextField
        focused
        required
        label="Bairro"
        type="text"
        className="inputField"
        onChange={({ target: { value } }) =>
          setFormInfo((prev) => {
            setFormValid({
              ...formValid,
              address: { ...formValid.address, neighborhood: true },
            });
            return {
              ...prev,
              address: { ...prev.address, neighborhood: value },
            };
          })
        }
        inputProps={{ maxLength: 40 }}
        sx={
          formValid.address.neighborhood
            ? {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '& fieldset': {
                    borderColor: '#7ddd00',
                  },
                },
              }
            : {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '& fieldset': {
                    borderColor: 'red',
                  },
                },
              }
        }
        value={formInfo.address.neighborhood}
      />
      {formValid.address.neighborhood ? (
        <></>
      ) : (
        <StyledErrorLabel id="neighborhoodError">
          Bairro inválido
        </StyledErrorLabel>
      )}
      <FormControl>
        <InputLabel
          sx={{ color: 'white !important', marginTop: '8px' }}
          id="state-select-label"
        >
          Estado *
        </InputLabel>
        <StyledSelect
          labelId="state-select-label"
          value={formInfo.address.state}
          label="Estado *"
          onChange={async ({ target: { value } }) => {
            await getCities(
              states.find((state) => state.estado === value)?.id_estado ?? 1,
            );
            setFormInfo((prev) => {
              setFormValid({
                ...formValid,
                address: { ...formValid.address, state: true },
              });
              return {
                ...prev,
                address: { ...prev.address, state: value as string },
              };
            });
          }}
          sx={
            formValid.address.state
              ? {
                  marginTop: '8px',
                  '.MuiSelect-select': {
                    color: 'white !important',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                    borderWidth: '2px !important',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                    borderWidth: '2px !important',
                  },
                }
              : {
                  marginTop: '8px',
                  '.MuiSelect-select': {
                    color: 'white',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red !important',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red',
                    borderWidth: '2px !important',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red',
                    borderWidth: '2px !important',
                  },
                }
          }
        >
          <StyledMenuItem disabled value={'0'}>
            <em>Selecione</em>
          </StyledMenuItem>
          {states.map(({ id_estado, estado, sigla_estado }) => (
            <StyledMenuItem value={estado} key={id_estado}>
              {`${estado} - ${sigla_estado}`}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </FormControl>
      {formValid.address.state ? (
        <></>
      ) : (
        <StyledErrorLabel id="stateError">
          É necessário selecionar um estado
        </StyledErrorLabel>
      )}
      <FormControl>
        <InputLabel
          sx={{ color: 'white !important', marginTop: '8px' }}
          id="city-select-label"
        >
          Cidade *
        </InputLabel>
        <StyledSelect
          labelId="city-select-label"
          value={formInfo.address.city_id}
          label="Cidade *"
          onChange={({ target: { value } }) =>
            setFormInfo((prev) => {
              setFormValid({
                ...formValid,
                address: { ...formValid.address, city_id: true },
              });
              return {
                ...prev,
                address: { ...prev.address, city_id: value as any },
              };
            })
          }
          sx={
            formValid.address.city_id
              ? {
                  marginTop: '8px',
                  '.MuiSelect-select': {
                    color: 'white !important',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                    borderWidth: '2px !important',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                    borderWidth: '2px !important',
                  },
                }
              : {
                  marginTop: '8px',
                  '.MuiSelect-select': {
                    color: 'white',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red !important',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red',
                    borderWidth: '2px !important',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red',
                    borderWidth: '2px !important',
                  },
                }
          }
        >
          <StyledMenuItem disabled value={0}>
            <em>Selecione</em>
          </StyledMenuItem>
          {cities.map(({ id_cidade, cidade }) => (
            <StyledMenuItem value={id_cidade} key={id_cidade}>
              {cidade}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </FormControl>
      {formValid.address.city_id ? (
        <></>
      ) : (
        <StyledErrorLabel id="cityError">
          É necessário selecionar uma cidade
        </StyledErrorLabel>
      )}
      <FormControl>
        <InputLabel
          sx={{ color: 'white !important', marginTop: '8px' }}
          id="education-level-select-label"
        >
          Grau de Instrução *
        </InputLabel>
        <StyledSelect
          labelId="education-level-select-label"
          value={formInfo.formation.formation_id}
          label="Grau de Instrução *"
          onChange={({ target: { value } }) =>
            setFormInfo((prev) => {
              setFormValid({
                ...formValid,
                formation: { ...formValid.formation, formation_id: true },
              });
              return {
                ...prev,
                formation: { ...prev.formation, formation_id: value as any },
              };
            })
          }
          sx={
            formValid.formation.formation_id
              ? {
                  marginTop: '8px',
                  '.MuiSelect-select': {
                    color: 'white !important',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                    borderWidth: '2px !important',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                    borderWidth: '2px !important',
                  },
                }
              : {
                  marginTop: '8px',
                  '.MuiSelect-select': {
                    color: 'white',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red !important',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red',
                    borderWidth: '2px !important',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red',
                    borderWidth: '2px !important',
                  },
                }
          }
        >
          <StyledMenuItem disabled value={0}>
            <em>Selecione</em>
          </StyledMenuItem>
          {formations.map(({ id_formacao, descricao }) => (
            <StyledMenuItem value={id_formacao} key={id_formacao}>
              {descricao}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </FormControl>
      {formValid.formation.formation_id ? (
        <></>
      ) : (
        <StyledErrorLabel id="educationLevelError">
          É necessário selecionar um grau de instrução
        </StyledErrorLabel>
      )}
      <InputTextField
        required
        focused
        label="Conclusão do Ensino Médio (mês/ano)"
        placeholder="MM/AAAA"
        type="text"
        className="inputField"
        onChange={({ target: { value } }) =>
          setFormInfo((prev) => {
            setFormValid({
              ...formValid,
              formation: {
                ...formValid.formation,
                high_school_graduation_date: true,
              },
            });
            return {
              ...prev,
              formation: {
                ...prev.formation,
                high_school_graduation_date: value as string,
              },
            };
          })
        }
        InputProps={{
          inputComponent: highSchoolCompletionMask as any,
        }}
        sx={
          formValid.formation.high_school_graduation_date
            ? {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '& fieldset': {
                    borderColor: '#7ddd00',
                  },
                },
              }
            : {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '& fieldset': {
                    borderColor: 'red',
                  },
                },
              }
        }
        value={formInfo.formation.high_school_graduation_date}
      />
      {formValid.formation.high_school_graduation_date ? (
        <></>
      ) : (
        <StyledErrorLabel id="highSchoolCompletionError">
          Data inválida
        </StyledErrorLabel>
      )}
      <FormControl>
        <InputLabel
          sx={{ color: 'white !important', marginTop: '8px' }}
          id="high-school-select-label"
        >
          Tipo de Instituição (Ensino Médio) *
        </InputLabel>
        <StyledSelect
          labelId="high-school-select-label"
          value={formInfo.formation.high_school_type}
          label="Tipo de Instituição (Ensino Médio) *"
          onChange={({ target: { value } }) =>
            setFormInfo((prev) => {
              setFormValid({
                ...formValid,
                formation: { ...formValid.formation, high_school_type: true },
              });
              return {
                ...prev,
                formation: {
                  ...prev.formation,
                  high_school_type: value as string,
                },
              };
            })
          }
          sx={
            formValid.formation.high_school_type
              ? {
                  marginTop: '8px',
                  '.MuiSelect-select': {
                    color: 'white !important',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                    borderWidth: '2px !important',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                    borderWidth: '2px !important',
                  },
                }
              : {
                  marginTop: '8px',
                  '.MuiSelect-select': {
                    color: 'white',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red !important',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red',
                    borderWidth: '2px !important',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red',
                    borderWidth: '2px !important',
                  },
                }
          }
        >
          <StyledMenuItem disabled value={'0'} key={0}>
            <em>Selecione</em>
          </StyledMenuItem>
          {highSchoolTypes.map(({ id_tipo, tipo }) => (
            <StyledMenuItem value={tipo} key={id_tipo}>
              {tipo}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </FormControl>
      {formValid.formation.high_school_type ? (
        <></>
      ) : (
        <StyledErrorLabel id="educationLevelError">
          É necessário selecionar um tipo de instituição
        </StyledErrorLabel>
      )}
      <ButtonsDiv>
        <ConfirmButton type="submit">AVANÇAR</ConfirmButton>
      </ButtonsDiv>
      {alerta === 1 ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <StyledP>
              Não é possível prosseguir no processo com conclusão do Ensino
              Médio posterior à 12/2023
            </StyledP>
            <BackButton
              onClick={handleModalButton}
              sx={{
                width: '200px',
                height: '45px',
                borderRadius: '0',
                backgroundColor: '#7ddd00 !important',
                color: 'black !important',
              }}
            >
              OK
            </BackButton>
          </Box>
        </Modal>
      ) : (
        <></>
      )}
    </MainForm>
  ) : (
    <MainForm onSubmit={handleUnderAgeSubmit}>
      <StyledPInfo>
        Para menores de idade, é necessário preencher abaixo as informações de
        um responsável legal
      </StyledPInfo>
      {errorAlert2 ? (
        <StyledInputAlert icon={<ErrorIcon fontSize="inherit" />}>
          Por favor, revise os valores dos campos
        </StyledInputAlert>
      ) : (
        <></>
      )}
      <FormControl>
        <InputLabel
          sx={{ color: 'white !important', marginTop: '8px' }}
          id="kinship-select-label"
        >
          Parentesco *
        </InputLabel>
        <StyledSelect
          labelId="kinship-select-label"
          value={formUAInfo.kinship_id}
          label="Parentesco *"
          onChange={({ target: { value } }) =>
            setFormUAInfo((prev) => {
              setFormUAValid({ ...formUAValid, kinship_id: true });
              return { ...prev, kinship_id: value as any };
            })
          }
          sx={
            formUAValid.kinship_id
              ? {
                  marginTop: '8px',
                  '.MuiSelect-select': {
                    color: 'white !important',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                    borderWidth: '2px !important',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                    borderWidth: '2px !important',
                  },
                }
              : {
                  marginTop: '8px',
                  '.MuiSelect-select': {
                    color: 'white',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red !important',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red',
                    borderWidth: '2px !important',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red',
                    borderWidth: '2px !important',
                  },
                }
          }
        >
          <StyledMenuItem disabled value={0}>
            <em>Selecione</em>
          </StyledMenuItem>
          {kinships.map(({ id_parentesco, parentesco }) => (
            <StyledMenuItem value={id_parentesco} key={id_parentesco}>
              {parentesco}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </FormControl>
      {formUAValid.kinship_id ? (
        <></>
      ) : (
        <StyledErrorLabel id="kinshipError">
          É necessário selecionar uma opção de parentesco
        </StyledErrorLabel>
      )}
      <InputTextField
        focused
        placeholder="João da Silva"
        required
        label="Nome do responsável"
        type="text"
        className="inputField"
        onChange={({ target: { value } }) =>
          setFormUAInfo((prev) => {
            setFormUAValid({ ...formUAValid, name: true });
            return { ...prev, name: value };
          })
        }
        inputProps={{ maxLength: 150 }}
        sx={
          formUAValid.name
            ? {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '& fieldset': {
                    borderColor: '#7ddd00',
                  },
                },
              }
            : {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '& fieldset': {
                    borderColor: 'red',
                  },
                },
              }
        }
        value={formUAInfo.name}
      />
      {formUAValid.name ? (
        <></>
      ) : (
        <StyledErrorLabel id="parentNameError">Nome inválido</StyledErrorLabel>
      )}
      <InputTextField
        required
        label="RG do responsável (somente números)"
        placeholder="00000000"
        focused
        type="text"
        className="inputField"
        onChange={({ target: { value } }) =>
          setFormUAInfo((prev) => {
            setFormUAValid({ ...formUAValid, rg: true });
            return { ...prev, rg: value };
          })
        }
        InputProps={{
          inputComponent: addressNumberMask as any,
        }}
        inputProps={{ maxLength: 20 }}
        sx={
          formUAValid.rg
            ? {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '& fieldset': {
                    borderColor: '#7ddd00',
                  },
                },
              }
            : {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '& fieldset': {
                    borderColor: 'red',
                  },
                },
              }
        }
        value={formUAInfo.rg}
      />
      {formUAValid.rg ? (
        <></>
      ) : (
        <StyledErrorLabel id="parentRgError">RG inválido</StyledErrorLabel>
      )}
      <InputTextField
        focused
        placeholder="000.000.000-00"
        required
        label="CPF do responsável"
        type="text"
        className="inputField"
        onChange={({ target: { value } }) =>
          setFormUAInfo((prev) => {
            setFormUAValid({ ...formUAValid, cpf: true });
            return { ...prev, cpf: value };
          })
        }
        InputProps={{
          inputComponent: CpfMask as any,
        }}
        sx={
          formUAValid.cpf
            ? {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '& fieldset': {
                    borderColor: '#7ddd00',
                  },
                },
              }
            : {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '& fieldset': {
                    borderColor: 'red',
                  },
                },
              }
        }
        value={formUAInfo.cpf}
      />
      {formUAValid.cpf ? (
        <></>
      ) : (
        <StyledErrorLabel id="parentCpfError">CPF inválido</StyledErrorLabel>
      )}
      <InputTextField
        focused
        placeholder="responsavel@xpeducacao.com.br"
        required
        label="Email do responsável"
        type="text"
        className="inputField"
        onChange={({ target: { value } }) =>
          setFormUAInfo((prev) => {
            setFormUAValid({ ...formUAValid, email: true });
            return { ...prev, email: value };
          })
        }
        inputProps={{ maxLength: 200 }}
        sx={
          formUAValid.email
            ? {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '& fieldset': {
                    borderColor: '#7ddd00',
                  },
                },
              }
            : {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '& fieldset': {
                    borderColor: 'red',
                  },
                },
              }
        }
        value={formUAInfo.email}
      />
      {formUAValid.email ? (
        <></>
      ) : (
        <StyledErrorLabel id="parentEmailError">
          Email inválido
        </StyledErrorLabel>
      )}
      <ButtonsDiv>
        <ConfirmButton type="submit">AVANÇAR</ConfirmButton>
        <BackButton type="button" onClick={handleUnderAgeBackButton}>
          VOLTAR
        </BackButton>
      </ButtonsDiv>
    </MainForm>
  );
};

export default Step3;
