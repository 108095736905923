import { BrowserRouter } from 'react-router-dom';
import ProcessoSeletivoRoutes from 'pages/processo-seletivo/routes';

const RouterApp = () => {
  return (
    <BrowserRouter>
      <ProcessoSeletivoRoutes />
    </BrowserRouter>
  );
};

export default RouterApp;
