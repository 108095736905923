import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Header from 'shared/components/main/header/xpe_faculdade';
import Footer from 'shared/components/main/footer/xpe_faculdade';
import { MainContainer } from 'shared/components/main/main_container';
import { BlackGlobalStyle } from 'shared/utils/styles/black_global_style';
import { RadioCanadaFontStyle } from 'shared/utils/styles/radio_canada_font_style';
import ButtonsDiv from '../../components/buttons-div';
import BackButton from '../../components/back-button';

const StyledP = styled.p`
  margin: 0 auto;
  width: min(500px, 80vw);
  margin-top: 15vh;
  text-align: center;
  font-size: 30px;
`;

const StyledButtonsDiv = styled(ButtonsDiv)`
  justify-content: space-evenly;
  align-items: center;
`;

const StyledBackButton = styled(BackButton)`
  margin: 0 !important;
`;

const ErrorProcessoSeletivo = () => {
  const navigate = useNavigate();

  const handleBackButton = () => {
    navigate('/');
  };

  const location: any = useLocation();

  return (
    <>
      <BlackGlobalStyle />
      <RadioCanadaFontStyle />
      <MainContainer>
        <Header home={false} />
        <StyledP>
          {location.state
            ? `Infelizmente não foi possível processar sua solicitação no momento. Tente novamente mais tarde. (ERRCODE-${location.state.errorMsg}).`
            : 'Infelizmente não foi possível processar sua solicitação no momento. Tente novamente mais tarde.'}
        </StyledP>
        <StyledButtonsDiv>
          <StyledBackButton onClick={handleBackButton}>VOLTAR</StyledBackButton>
        </StyledButtonsDiv>
      </MainContainer>
      <Footer />
    </>
  );
};

export default ErrorProcessoSeletivo;
