import Header from 'shared/components/main/header/xpe_faculdade';
import Footer from 'shared/components/main/footer/xpe_faculdade';
import { MainContainer } from 'shared/components/main/main_container';
import Main from './Main';

import { BlackGlobalStyle } from 'shared/utils/styles/black_global_style';

import { RadioCanadaFontStyle } from 'shared/utils/styles/radio_canada_font_style';

interface IProps {
  page: string;
}

const ProcessoSeletivo = (props: IProps) => {
  return (
    <>
      <BlackGlobalStyle />
      <RadioCanadaFontStyle />
      <MainContainer>
        <Header home={false}  />
        <Main page={props.page} />
      </MainContainer>
      <Footer />
    </>
  );
};

export default ProcessoSeletivo;
