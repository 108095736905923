import './App.css';

import RouterApp from 'main/routes/routes';

function App() {
  return (
    <>
      <RouterApp />
    </>
  );
}

export default App;
