import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmButton from '../../components/confirm-button';
import ButtonsDiv from '../../components/buttons-div';
import MainForm from '../../components/main-form';
import BackButton from '../../components/back-button';
import styled from 'styled-components';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Modal,
} from '@mui/material';
import ErrorLabel from '../../components/error-label';
import { headers } from '../../shared/config';
import { randomApi } from '../../shared/random';
import { RegisterForm } from '../Register';
import {
  CityResponse,
  FormationResponse,
  GenreResponse,
  GetCitiesResponse,
  GetFormationsResponse,
  GetGenreIdentitiesResponse,
  GetKinshipsResponse,
  GetStatesResponse,
  KinshipResponse,
  StateResponse,
} from './step3';
import {
  EthnicResponse,
  GetEthnicsResponse,
  GetModalitiesResponse,
  ModalityResponse,
} from './step7';
import {
  AssistiveTechnologyResponse,
  GetAssistiveTechnologiesResponse,
} from './step6';

/** Definição de componentes */
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'min(600px, 95vw)',
  backgroundColor: 'rgb(42,42,42)',
  p: 4,
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  fontSize: '26px',
  flexDirection: 'column',
  justifyContent: 'center',
  fontWeight: 'regular !important',
  textAlign: 'center',
  height: '540px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'auto',
  padding: '40px 0px',
  '@media (min-width: 600px)': {
    height: '440px',
  },
};

const ProgressDiv = styled.div`
  display: flex !important;
  position: fixed;
  top: 0;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  width: 100%;
  height: calc(100%);
`;

const StyledCircularProgress = styled(CircularProgress)`
  position: fixed !important;
  top: 50% !important;
  color: #7ddd00 !important;
  width: 50px !important;
  height: 50px !important;
`;

const StyledErrorLabel = styled(ErrorLabel)`
  width: min(80%, 80vw);
  text-align: left;
  margin-top: -14px;
  margin-bottom: 20px;
  margin-left: -28px;
`;

const StyledErrorLabel2 = styled(StyledErrorLabel)`
  margin-top: -12px;
  margin-bottom: 15px;
`;

const GreenFormLabel = styled(FormControlLabel)`
  & .MuiCheckbox-root {
    color: white !important;
  }
  & .Mui-checked {
    color: #7ddd00 !important;
  }
  text-align: left;
  width: min(95%, 90vw);
  margin-left: 16px !important;
  margin-bottom: 15px;
`;

const StyledA = styled.a`
  color: white;
`;

const DivButtons = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
    margin-top: 35px;
  }
`;

const LastButton = styled(BackButton)`
  margin-top: 15px !important;

  @media (min-width: 600px) {
    margin-top: 0px !important;
  }
`;

const StyledUl = styled.ul`
  font-size: 14px;
  margin-top: -8px;
`;

const StyledLi = styled.li`
  text-align: left;
  max-width: min(90%, 90vw);
  margin-left: 70px;
  padding-right: 20px !important;

  @media (min-width: 600px) {
    padding-right: 0px !important;
    margin-left: 35px;
    width: 480px;
  }
`;

const StyledModalP = styled.p`
  font-size: 15px;
  width: 100%;
  text-align: left;
  line-height: 16px;
`;

const InputDiv = styled.div`
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledConfirmButton = styled(ConfirmButton)`
  width: 200px;
`;

const StyledLabel = styled.label`
  font-weight: 700;
  width: 245px;
  text-align: right;
  line-height: 16px;
`;

const StyledSubP = styled.p`
  color: #7ddd00;
  margin-bottom: 24px;
`;

const StyledSpan = styled.span`
  padding-left: 5px;
  width: 245px;
  text-align: left;
  line-height: 16px;
`;

const StyledP = styled.p`
  text-align: center;
  font-size: 24px;
  color: #7ddd00;
  margin-bottom: 50px;
`;

const StyledButtonDiv = styled(ButtonsDiv)`
  margin-top: 20px !important;
`;

/** Definição de interfaces */
interface IProps {
  candidateToken: string;
  updateStep: Function;
  updateSpinner: Function;
  form: RegisterForm;
  genres: GenreResponse[];
  setGenres: Function;
  states: StateResponse[];
  setStates: Function;
  cities: CityResponse[];
  setCities: Function;
  formations: FormationResponse[];
  setFormations: Function;
  kinships: KinshipResponse[];
  setKinships: Function;
  assistiveTechnologies: AssistiveTechnologyResponse[];
  setAssistiveTechnologies: Function;
  ethnics: EthnicResponse[];
  setEthnics: Function;
  modalities: ModalityResponse[];
  setModalities: Function;
}

interface Step8Form {
  firstCheckbox: boolean;
  secondCheckbox: boolean;
  thirdCheckbox: boolean;
}

interface Step8FormValid {
  firstCheckbox: boolean;
  secondCheckbox: boolean;
  thirdCheckbox: boolean;
}

interface ConcludeResponse {
  success: boolean;
}

interface ISummaryShow {
  label: string;
  screen: number;
  showNull: boolean;
}

interface IStep1Summary {
  name: ISummaryShow;
  social_name: ISummaryShow;
  cpf: ISummaryShow;
  email: ISummaryShow;
  phone: ISummaryShow;
}

interface IStep3Summary {
  rg: ISummaryShow;
  birthday: ISummaryShow;
  nationality: ISummaryShow;
  genre_id: ISummaryShow;
  address: {
    cep: ISummaryShow;
    address: ISummaryShow;
    number: ISummaryShow;
    complement: ISummaryShow;
    neighborhood: ISummaryShow;
    city_id: ISummaryShow;
    state: ISummaryShow;
  };
  formation: {
    formation_id: ISummaryShow;
    high_school_graduation_date: ISummaryShow;
    high_school_type: ISummaryShow;
  };
  responsible: {
    name: ISummaryShow;
    rg: ISummaryShow;
    cpf: ISummaryShow;
    email: ISummaryShow;
    kinship_id: ISummaryShow;
  };
}

interface IStep5Summary {
  enem_year: ISummaryShow;
}

interface IStep6Summary {
  has_disability: ISummaryShow;
  cid1: ISummaryShow;
  cid2: ISummaryShow;
  sync_support: ISummaryShow;
  resource_id: ISummaryShow;
  description: ISummaryShow;
}

interface IStep7Summary {
  ethnicGroup: ISummaryShow;
  modality: ISummaryShow;
}

const Step8 = (props: IProps) => {
  /** Variaveis iniciais */
  const {
    candidateToken,
    updateStep,
    updateSpinner,
    form,
    genres,
    setGenres,
    states,
    setStates,
    cities,
    setCities,
    formations,
    setFormations,
    kinships,
    setKinships,
    assistiveTechnologies,
    setAssistiveTechnologies,
    ethnics,
    setEthnics,
    modalities,
    setModalities,
  } = props;

  const navigate = useNavigate();

  /** Estados */
  const [spinner, setSpinner] = useState<boolean>(false);

  const [formInfo, setFormInfo] = useState<Step8Form>({
    firstCheckbox: false,
    secondCheckbox: false,
    thirdCheckbox: false,
  });

  const [formValid, setFormValid] = useState<Step8FormValid>({
    firstCheckbox: true,
    secondCheckbox: true,
    thirdCheckbox: true,
  });

  const [open, setOpen] = useState<boolean>(false);

  const [submitted, setSubmitted] = useState<boolean>(false);

  /** Definição do map1 */
  const step1Summary: IStep1Summary = {
    name: { label: 'Nome', screen: 1, showNull: true },
    social_name: { label: 'Nome social', screen: 1, showNull: true },
    email: { label: 'Email', screen: 1, showNull: true },
    cpf: { label: 'CPF', screen: 1, showNull: true },
    phone: { label: 'Telefone', screen: 1, showNull: true },
  };

  /** Definição do map3 */
  const step3Summary: IStep3Summary = {
    rg: { label: 'RG', screen: 3, showNull: true },
    birthday: { label: 'Data de Nascimento', screen: 3, showNull: true },
    genre_id: { label: 'Gênero', screen: 3, showNull: true },
    nationality: { label: 'Nacionalidade', screen: 3, showNull: true },
    address: {
      cep: { label: 'CEP', screen: 3, showNull: true },
      address: { label: 'Logradouro', screen: 3, showNull: true },
      number: { label: 'Número', screen: 3, showNull: true },
      complement: { label: 'Complemento', screen: 3, showNull: true },
      neighborhood: { label: 'Bairro', screen: 3, showNull: true },
      city_id: { label: 'Cidade', screen: 3, showNull: true },
      state: { label: 'Estado', screen: 3, showNull: true },
    },
    formation: {
      formation_id: { label: 'Formação', screen: 3, showNull: true },
      high_school_graduation_date: {
        label: 'Conclusão do Ensino Médio',
        screen: 3,
        showNull: true,
      },
      high_school_type: {
        label: 'Tipo de instituição',
        screen: 3,
        showNull: true,
      },
    },
    responsible: {
      name: { label: 'Nome (Responsável)', screen: 3, showNull: false },
      rg: { label: 'RG (Responsável)', screen: 3, showNull: false },
      cpf: { label: 'CPF (Responsável)', screen: 3, showNull: false },
      email: { label: 'Email (Responsável)', screen: 3, showNull: false },
      kinship_id: { label: 'Parentesco', screen: 3, showNull: false },
    },
  };

  const step5Summary: IStep5Summary = {
    enem_year: { label: 'Ano do ENEM', screen: 5, showNull: true },
  };

  const step6Summary: IStep6Summary = {
    has_disability: { label: 'Possui deficiência', screen: 6, showNull: true },
    cid1: { label: 'CID1', screen: 6, showNull: false },
    cid2: { label: 'CID2', screen: 6, showNull: true },
    sync_support: { label: 'Suporte assíncrono', screen: 6, showNull: false },
    resource_id: {
      label: 'Recurso de acessibilidade',
      screen: 6,
      showNull: false,
    },
    description: { label: 'Descrição', screen: 6, showNull: true },
  };

  const step7Summary: IStep7Summary = {
    ethnicGroup: { label: 'Etnia/Raça', screen: 7, showNull: true },
    modality: {
      label: 'Modalidade de Candidatura',
      screen: 7,
      showNull: true,
    },
  };

  const summaries: (
    | IStep1Summary
    | IStep3Summary
    | IStep5Summary
    | IStep6Summary
    | IStep7Summary
  )[] = [step1Summary, step3Summary, step5Summary, step6Summary, step7Summary];

  const summariesTitles = [
    'Informações Iniciais',
    'Dados Pessoais',
    'Informações de Prova',
    'Acessibilidade',
    'Dados Étnico-raciais',
  ];

  /** Efeito para visão do componente abrir no topo da tela */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /** Efeito para valores que não houver */
  useEffect(() => {
    const getGenderIdentities = async () => {
      try {
        const {
          data: { success, response },
        } = await randomApi.get<GetGenreIdentitiesResponse>(
          '/genre_identities/',
          {
            headers: headers(candidateToken),
            timeout: 30000,
          },
        );

        if (!success) {
          updateSpinner(false);
          navigate('/erro-processo-seletivo');
          return;
        }

        setGenres(response as GenreResponse[]);
      } catch {
        updateSpinner(false);
        navigate('/erro-processo-seletivo');
        return;
      }
    };

    const effect = async () => {
      updateSpinner(true);
      if (genres.length === 0) await getGenderIdentities();
      updateSpinner(false);
    };

    effect();
  }, [updateSpinner, navigate, candidateToken, genres, setGenres]);

  /** Efeito para valores que não houver */
  useEffect(() => {
    const getStates = async () => {
      try {
        const {
          data: { success, response },
        } = await randomApi.get<GetStatesResponse>('/states/', {
          headers: headers(candidateToken),
          timeout: 30000,
        });

        if (!success) {
          updateSpinner(false);
          navigate('/erro-processo-seletivo');
          return;
        }

        setStates(response as StateResponse[]);

        if (cities.length === 0) {
          const stateId = response?.find(
            (state) => state.estado === form.address.state,
          )?.id_estado;

          try {
            const {
              data: { success, response },
            } = await randomApi.get<GetCitiesResponse>(
              `/cities/?state_id=${stateId}`,
              {
                headers: headers(candidateToken),
                timeout: 30000,
              },
            );

            updateSpinner(false);

            if (!success) {
              navigate('/erro-processo-seletivo');
              return;
            }

            setCities(response as CityResponse[]);
          } catch {
            updateSpinner(false);
            navigate('/erro-processo-seletivo');
            return;
          }
        }
      } catch {
        updateSpinner(false);
        navigate('/erro-processo-seletivo');
        return;
      }
    };

    const effect = async () => {
      updateSpinner(true);
      if (states.length === 0) await getStates();
      updateSpinner(false);
    };

    effect();
  }, [
    updateSpinner,
    navigate,
    candidateToken,
    states,
    setStates,
    cities,
    setCities,
    form.address.state,
  ]);

  /** Efeito para valores que não houver */
  useEffect(() => {
    const getFormations = async () => {
      try {
        const {
          data: { success, response },
        } = await randomApi.get<GetFormationsResponse>('/formations/', {
          headers: headers(candidateToken),
          timeout: 30000,
        });

        if (!success) {
          updateSpinner(false);
          navigate('/erro-processo-seletivo');
          return;
        }

        setFormations(response as FormationResponse[]);
      } catch {
        updateSpinner(false);
        navigate('/erro-processo-seletivo');
        return;
      }
    };

    const effect = async () => {
      updateSpinner(true);
      if (formations.length === 0) await getFormations();
      updateSpinner(false);
    };

    effect();
  }, [
    updateSpinner,
    navigate,
    candidateToken,
    genres,
    formations,
    setFormations,
  ]);

  /** Efeito para valores que não houver */
  useEffect(() => {
    const getKinships = async () => {
      try {
        const {
          data: { success, response },
        } = await randomApi.get<GetKinshipsResponse>('/kinships/', {
          headers: headers(candidateToken),
          timeout: 30000,
        });

        if (!success) {
          updateSpinner(false);
          navigate('/erro-processo-seletivo');
          return;
        }

        setKinships(response as KinshipResponse[]);
      } catch {
        updateSpinner(false);
        navigate('/erro-processo-seletivo');
        return;
      }
    };

    const effect = async () => {
      updateSpinner(true);
      if (kinships.length === 0) await getKinships();
      updateSpinner(false);
    };

    effect();
  }, [updateSpinner, navigate, candidateToken, kinships, setKinships]);

  /** Efeito para valores que não houver */
  useEffect(() => {
    const getAssistiveTechnologies = async () => {
      try {
        const {
          data: { success, response },
        } = await randomApi.get<GetAssistiveTechnologiesResponse>(
          '/assistive_technologies/',
          {
            headers: headers(candidateToken),
            timeout: 30000,
          },
        );

        updateSpinner(false);

        if (!success) {
          navigate('/erro-processo-seletivo');
          return;
        }

        setAssistiveTechnologies(response as AssistiveTechnologyResponse[]);
      } catch {
        updateSpinner(false);
        navigate('/erro-processo-seletivo');
        return;
      }
    };

    const effect = async () => {
      updateSpinner(true);
      if (assistiveTechnologies.length === 0) await getAssistiveTechnologies();
      updateSpinner(false);
    };

    effect();
  }, [
    updateSpinner,
    navigate,
    candidateToken,
    assistiveTechnologies,
    setAssistiveTechnologies,
  ]);

  /** Efeito para valores que não houver */
  useEffect(() => {
    const getEthnics = async () => {
      try {
        const {
          data: { success, response },
        } = await randomApi.get<GetEthnicsResponse>('/ethnics/', {
          headers: headers(candidateToken),
          timeout: 30000,
        });

        if (!success) {
          updateSpinner(false);
          navigate('/erro-processo-seletivo');
          return;
        }

        setEthnics(response as EthnicResponse[]);
      } catch {
        updateSpinner(false);
        navigate('/erro-processo-seletivo');
        return;
      }
    };

    const effect = async () => {
      updateSpinner(true);
      if (ethnics.length === 0) await getEthnics();
      updateSpinner(false);
    };

    effect();
  }, [updateSpinner, navigate, candidateToken, ethnics, setEthnics]);

  /** Efeito para valores que não houver */
  useEffect(() => {
    const getModalities = async () => {
      try {
        const {
          data: { success, response },
        } = await randomApi.get<GetModalitiesResponse>(
          `/application_modalities/`,
          {
            headers: headers(candidateToken),
            timeout: 30000,
          },
        );

        if (!success) {
          updateSpinner(false);
          navigate('/erro-processo-seletivo');
          return;
        }

        setModalities(response as ModalityResponse[]);
      } catch (e: any) {
        return false;
      }
    };

    const effect = async () => {
      updateSpinner(true);
      if (modalities.length === 0) await getModalities();
      updateSpinner(false);
    };

    effect();
  }, [updateSpinner, navigate, candidateToken, modalities, setModalities]);

  /** Função que controla o fechamento do modal */
  const handleClose = () => {
    setFormInfo({
      firstCheckbox: false,
      secondCheckbox: false,
      thirdCheckbox: false,
    });
    setFormValid({
      firstCheckbox: true,
      secondCheckbox: true,
      thirdCheckbox: true,
    });
    setOpen(false);
  };

  /** Função que controla o botão do modal */
  const handleModalButton = () => {
    setFormInfo({
      firstCheckbox: false,
      secondCheckbox: false,
      thirdCheckbox: false,
    });
    setFormValid({
      firstCheckbox: true,
      secondCheckbox: true,
      thirdCheckbox: true,
    });
    setOpen(false);
  };

  /** Função de confirmação (abertura do modal) */
  const handleConfirmButton = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      setOpen(true);
    },
    [],
  );

  /** Função de confirmação do modal */
  const handleConfirmModalButton = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();

      setFormValid((prev) => {
        return { ...prev, firstCheckbox: formInfo.firstCheckbox };
      });
      setFormValid((prev) => {
        return { ...prev, secondCheckbox: formInfo.secondCheckbox };
      });
      setFormValid((prev) => {
        return { ...prev, thirdCheckbox: formInfo.thirdCheckbox };
      });

      setSubmitted(true);
    },
    [formInfo],
  );

  /** Efeito quando todos os campos estão válidos */
  useEffect(() => {
    const handleSubmitForm = async () => {
      try {
        const {
          data: { success },
        } = await randomApi.post<ConcludeResponse>(
          `candidates/conclude_form`,
          {},
          {
            headers: headers(candidateToken),
            timeout: 30000,
          },
        );

        updateSpinner(false);

        if (!success) {
          navigate('/erro-processo-seletivo');
          return;
        }

        navigate('/sucesso-inscricao');
      } catch {
        updateSpinner(false);
        navigate('/erro-processo-seletivo');
        return;
      }
    };

    const effect = async () => {
      if (submitted) {
        let minOneFalse = false;

        for (const val in formValid) {
          if (!formValid[val as keyof Step8FormValid]) minOneFalse = true;
        }

        setSubmitted(false);

        if (!minOneFalse) {
          setSpinner(true);
          await handleSubmitForm();
        }
      }
    };

    effect();
  }, [
    formInfo,
    formValid,
    submitted,
    setSpinner,
    navigate,
    candidateToken,
    updateSpinner,
  ]);

  const handleBackButton = () => {
    updateStep(6);
  };

  return (
    <>
      <MainForm onSubmit={handleConfirmButton}>
        <StyledP>
          Por favor, revise os dados antes de confirmar as informações
        </StyledP>
        {summaries.map((stepSummary, summariesIndex) => {
          return Object.keys(stepSummary).map((stepKey, stepIndex) => {
            if (['address', 'formation', 'responsible'].includes(stepKey)) {
              return Object.keys((stepSummary as any)[stepKey]).map(
                (subStepKey, subStepIndex) => {
                  const elementKey = summariesIndex + stepIndex + subStepIndex;

                  let value = (form as any)[stepKey][
                    subStepKey as keyof RegisterForm
                  ];

                  if (subStepKey === 'city_id')
                    value = cities.find(
                      (city: CityResponse) =>
                        city.id_cidade === (form as any)[stepKey][subStepKey],
                    )?.cidade;

                  if (subStepKey === 'formation_id')
                    value = formations.find(
                      (formation: FormationResponse) =>
                        formation.id_formacao ===
                        (form as any)[stepKey][subStepKey],
                    )?.descricao;

                  if (subStepKey === 'kinship_id')
                    value = kinships.find(
                      (kinship: KinshipResponse) =>
                        kinship.id_parentesco ===
                        (form as any)[stepKey][subStepKey],
                    )?.parentesco;

                  if (value === false) value = 'Não';
                  if (value === true) value = 'Sim';
                  if (value === null || value === undefined || value === '')
                    value = '-';

                  return value === '-' &&
                    !(stepSummary as any)[stepKey][subStepKey].showNull ? (
                    <></>
                  ) : (
                    <InputDiv>
                      <StyledLabel key={elementKey}>{`${
                        (stepSummary as any)[stepKey][subStepKey].label
                      }:`}</StyledLabel>
                      <StyledSpan key={`${elementKey}2`}>{value}</StyledSpan>
                    </InputDiv>
                  );
                },
              );
            } else {
              const elementKey = summariesIndex + stepIndex;

              let value = (form as any)[stepKey];

              if (stepKey === 'genre_id')
                value = genres.find(
                  (genre: GenreResponse) =>
                    genre.id_identidade_genero === form[stepKey],
                )?.identidade_genero;

              if (stepKey === 'ethnicGroup')
                value = ethnics.find(
                  (ethnic: EthnicResponse) =>
                    ethnic.id_raca_cor === form[stepKey],
                )?.raca_cor;

              if (stepKey === 'modality')
                value = modalities.find(
                  (modality: ModalityResponse) =>
                    modality.id_processo_seletivo_mod_candit === form[stepKey],
                )?.modalidade_candidatura;

              if (stepKey === 'resource_id')
                value = assistiveTechnologies.find(
                  (assistiveTechnology: AssistiveTechnologyResponse) =>
                    assistiveTechnology.id_tecnologia_assistiva ===
                    form[stepKey],
                )?.tecnologia_assistiva;

              if (value === false) value = 'Não';
              if (value === true) value = 'Sim';
              if (value === null || value === undefined || value === '')
                value = '-';

              if (
                (stepKey === 'cid2' || stepKey === 'description') &&
                form['has_disability'] === false
              )
                return <></>;

              return value === '-' &&
                !(stepSummary as any)[stepKey].showNull ? (
                <></>
              ) : stepIndex === 0 ? (
                <>
                  <StyledSubP>{summariesTitles[summariesIndex]}</StyledSubP>
                  <InputDiv>
                    <StyledLabel key={elementKey}>{`${
                      (stepSummary as any)[stepKey].label
                    }:`}</StyledLabel>
                    <StyledSpan key={`${elementKey}2`}>{value}</StyledSpan>
                  </InputDiv>
                </>
              ) : (
                <InputDiv>
                  <StyledLabel key={elementKey}>{`${
                    (stepSummary as any)[stepKey].label
                  }:`}</StyledLabel>
                  <StyledSpan key={`${elementKey}2`}>{value}</StyledSpan>
                </InputDiv>
              );
            }
          });
        })}
        <StyledButtonDiv>
          <StyledConfirmButton type="submit">
            CONFIRMAR INFORMAÇÕES
          </StyledConfirmButton>
          <BackButton type="button" onClick={handleBackButton}>
            VOLTAR
          </BackButton>
        </StyledButtonDiv>
      </MainForm>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          {spinner ? (
            <ProgressDiv>
              <StyledCircularProgress />
            </ProgressDiv>
          ) : (
            <></>
          )}
          {/**@ts-ignore */}
          <Box sx={style}>
            <GreenFormLabel
              control={
                <Checkbox
                  onChange={({ target: { checked } }) =>
                    setFormInfo((prev) => {
                      setFormValid({ ...formValid, firstCheckbox: true });
                      return { ...prev, firstCheckbox: checked };
                    })
                  }
                />
              }
              label={
                <StyledModalP>
                  Declaro que li e estou de acordo com os termos presentes no{' '}
                  <StyledA
                    href="https://irp.cdn-website.com/35665dd9/files/uploaded/2%C2%BA%20Edital%20Processo%20Seletivo%20%20-%20Faculdade%20XP%20Educa%C3%A7%C3%A3o%20-IGTI%20%2001.2023_130123.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <u>edital publicado</u>
                  </StyledA>
                  .
                </StyledModalP>
              }
            />
            {formValid.firstCheckbox ? (
              <></>
            ) : (
              <StyledErrorLabel id="firstCheckboxError">
                É necessário confirmar essa opção
              </StyledErrorLabel>
            )}
            <GreenFormLabel
              control={
                <Checkbox
                  onChange={({ target: { checked } }) =>
                    setFormInfo((prev) => {
                      setFormValid({ ...formValid, secondCheckbox: true });
                      return { ...prev, secondCheckbox: checked };
                    })
                  }
                />
              }
              label={
                <StyledModalP>
                  Concordo com a coleta e tratamento dos meus dados pessoais,
                  que podem ser utilizados para fins analíticos e envio de
                  conteúdo informativo e publicitário sobre produtos, serviços e
                  assuntos gerais, pelo grupo XP Inc. e parceiros, nos termos da{' '}
                  <StyledA
                    href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <u>Lei Geral de Proteção de Dados</u>
                  </StyledA>
                  .{' '}
                  <StyledA
                    href="https://www.xpeducacao.com.br/politica-de-privacidade"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <u>Política de Tratamento de Dados</u>
                  </StyledA>
                  .
                </StyledModalP>
              }
            />
            {formValid.secondCheckbox ? (
              <></>
            ) : (
              <StyledErrorLabel id="secondCheckboxError">
                É necessário confirmar essa opção
              </StyledErrorLabel>
            )}
            <GreenFormLabel
              control={
                <Checkbox
                  onChange={({ target: { checked } }) =>
                    setFormInfo((prev) => {
                      setFormValid({ ...formValid, thirdCheckbox: true });
                      return { ...prev, thirdCheckbox: checked };
                    })
                  }
                />
              }
              label={
                <StyledModalP>
                  Estou ciente que, caso seja aprovado no processo seletivo,
                  devo providenciar e apresentar no ato de matrícula os
                  seguintes documentos:
                </StyledModalP>
              }
            />
            {formValid.thirdCheckbox ? (
              <></>
            ) : (
              <StyledErrorLabel2 id="thirdCheckboxError">
                É necessário confirmar essa opção
              </StyledErrorLabel2>
            )}
            <StyledUl>
              <StyledLi>
                Comprovante de conclusão de ensino médio ou equivalente;
              </StyledLi>
              <StyledLi>RG e CPF;</StyledLi>
              <StyledLi>Comprovante de endereço;</StyledLi>
              <StyledLi>Título de eleitor frente e verso;</StyledLi>
              <StyledLi>Comprovante de quitação eleitoral;</StyledLi>
              <StyledLi>
                Atestado de quitação militar (somente sexo masculino);
              </StyledLi>
              <StyledLi>
                Comprovante do CadÚnico (somente quem solicitar isenção da taxa
                de inscrição);
              </StyledLi>
            </StyledUl>
            <DivButtons>
              <BackButton
                onClick={handleModalButton}
                sx={{
                  width: '220px',
                  height: '45px',
                  borderRadius: '0',
                  backgroundColor: '#7ddd00 !important',
                  color: 'black !important',
                }}
              >
                CANCELAR
              </BackButton>
              <LastButton
                onClick={handleConfirmModalButton}
                sx={{
                  lineHeight: '18px',
                  width: '220px',
                  height: '45px',
                  borderRadius: '0',
                  backgroundColor: '#7ddd00 !important',
                  color: 'black !important',
                }}
              >
                ACEITAR E CONFIRMAR INFORMAÇÕES
              </LastButton>
            </DivButtons>
          </Box>
        </>
      </Modal>
    </>
  );
};

export default Step8;
