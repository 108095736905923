import axios from 'axios';

const random = () => {
  // hmg
  // const value = Math.floor(Math.random() * 1) + 1; //quantidade de servidores
  // prod
  // const value = Math.floor(Math.random() * 11) + 1; //quantidade de servidores
  // const value = Math.floor(Math.random() * 5) + 1; //quantidade de servidores

  const value = 3;

  let randomBaseUrl = process.env[`REACT_APP_API_BASE_URL_${value}`];

  if (!randomBaseUrl) {
    randomBaseUrl = process.env.REACT_APP_API_BASE_URL_1;
  }

  // return randomBaseUrl;
  return process.env.REACT_APP_API_BASE_URL_1;
};

const randomApi = axios.create({
  baseURL: random(),
});

export { randomApi };
