export const formatEmail = (email: string) => {
  let formattedEmail = email;
  if (formattedEmail) {
    formattedEmail = formattedEmail.trim().toLowerCase();

    if (formattedEmail.indexOf('+') !== -1) {
      formattedEmail = formattedEmail.slice(0, formattedEmail.indexOf('+')) + formattedEmail.slice(formattedEmail.indexOf('@'), formattedEmail.length);
    }
  }

  return formattedEmail;
};

export const formatName = (name: string) => {
  let formattedName = name;
  if (formattedName) {
    const partitionedFormattedName = formattedName.trim().toLowerCase().split(' ');

    for (let i = 0; i < partitionedFormattedName.length; i += 1) {
      const prev = partitionedFormattedName[i];
      if (!(prev[0] === 'd' && prev.length === 2 && i !== 0 && i !== (partitionedFormattedName.length - 1))) {
        partitionedFormattedName[i] = prev.charAt(0).toUpperCase() + prev.slice(1);
      }
    }

    formattedName = partitionedFormattedName.join(' ');
  }

  return formattedName;
};
