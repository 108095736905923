import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from 'shared/assets/images/faculdade_xpe_branco.png';
import styled from 'styled-components';
import { Buffer } from 'buffer';

const HeaderElement = styled.header`
  /* background-color: red; */
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* max-width: max(1300px, 80vw); */
  /* margin-top: 10px; */
  /* margin-bottom: 40px; */

  @media (min-width: 960px) {
    /* padding-top: 15px 0; */
    justify-content: space-between;
  }
`;

const LogoImg = styled.img`
  height: 90px;
  /* background-color: yellow; */
`;

const RightDiv = styled.div`
  /* background-color: yellow; */
  // margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledUsernDiv = styled.div` 
  display: flex;
  align-items: center;
  justify-content: center;
  color: #CCCCCC;
  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
`;

const StyledNameP = styled.p`
  margin: 5px;
  color: #CCCCCC;
  font-size: 16px;
  text-align: center;
  @media (max-width: 700px) {
    font-size: 15px;
  }
`;

const StyledLogoutButton = styled.button`
  padding: 0;
  background-color: black;
  color: #CCCCCC;
  border: none;
  font-size: 16px;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  @media (max-width: 700px) {
    font-size: 15px;
  }
`;

// const ButtonElement = styled(Button)`
//   height: 40px;
//   margin: 0 5px !important;

//   @media (max-width: 959px) {
//     display: none;
//   }
// `;

const Header = (props: any) => {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState<string>('');

  useEffect(() => {
    if (sessionStorage.getItem('fi1') !== null) {
      const formInfo = JSON.parse(sessionStorage.getItem('fi1')!);
      const name = Buffer.from(formInfo.a, 'base64').toString('utf-8');
      const socialName = Buffer.from(formInfo.b, 'base64').toString('utf-8');
      if (socialName) {
        const firstName = socialName.split(' ')[0].toLowerCase();
        const formattedFirstName = firstName[0].toUpperCase() + firstName.slice(1);
        setFirstName(formattedFirstName);
      } else {
        const firstName = name.split(' ')[0].toLowerCase();
        const formattedFirstName = firstName[0].toUpperCase() + firstName.slice(1);
        setFirstName(formattedFirstName);
      }
    }
  }, [props]);

  const handleLogoutButton = () => {
    navigate('/cadastro');
    sessionStorage.clear();
    window.location.reload();
  }

  return (
    <HeaderElement>
      <LogoImg src={logo} alt="Logo XPe Multi" />
      <RightDiv>
        {/* <ButtonElement variant="contained">HOME</ButtonElement> */}
        {/* <ButtonElement variant="contained">AMBIENTE DE ENSINO</ButtonElement> */}
      </RightDiv>
      {
        (!props.home && firstName) ? (
          <StyledUsernDiv>
            <StyledNameP>{`Olá, ${firstName}.`}</StyledNameP>
            <StyledLogoutButton
              type="button"
              onClick={handleLogoutButton}
            >
              Sair
            </StyledLogoutButton>
          </StyledUsernDiv>
        ) : null
      }
    </HeaderElement>
  );
};

export default Header;
