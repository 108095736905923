import { Button } from '@mui/material';
import styled from 'styled-components';

const ConfirmButton = styled(Button)`
  background-color: ${(props) =>
    props.disabled ? '#2e4e04 !important' : '#7ddd00 !important'};
  color: black !important;
  font-weight: 700 !important;
  width: min(150px, 90vw);
`;

export default ConfirmButton;
