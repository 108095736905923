/** Arquivo de configuração do axios. */

import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const headers = (token: string) => {
  return {
    Authorization: `Bearer ${token}`,
  };
};

export { api, headers };
