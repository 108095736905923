import { useCallback, useEffect, useState, forwardRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import { CpfMask, PhoneMask } from '../../components/masks';
import {
  validateName,
  validateEmail,
  validateCpf,
  validatePhone,
  validateSocialName,
} from '../../shared/validators';
import InputTextField from '../../components/input-text-field';
import ConfirmButton from '../../components/confirm-button';
import ButtonsDiv from '../../components/buttons-div';
import ErrorLabel from '../../components/error-label';
import MainForm from '../../components/main-form';
import { randomApi } from '../../shared/random';
import { RiAlertLine } from 'react-icons/ri';
import {
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Tooltip,
  Checkbox,
  Alert,
} from '@mui/material';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import {
  formatName,
  formatEmail,
} from '../../../../shared/utils/functions/dataCleaners';
import ErrorIcon from '@mui/icons-material/Error';
import { RegisterForm } from '../Register';

/** Definição de componentes */
const InfoCircle = forwardRef(function InfoCircle(props: any, ref: any) {
  return (
    <div {...props} ref={ref}>
      <BsFillInfoCircleFill />
    </div>
  );
});

const StyledIntrop = styled.p`
  width: 100%;
  text-align: center;
  font-size: 20px;
  margin-bottom: 40px;
`;

const SocialNameDiv = styled.div`
  position: relative;
  .icon {
    color: #7ddd00 !important;
    position: absolute;
    right: 0;
    top: -5px;
    padding: 10px 10px;
    z-index: 1;
  }
`;

const StyledHintDiv = styled.div`
  color: yellow;
  font-size: 13px;
  margin-top: -5px;
  margin-left: 3px;
  margin-bottom: 8px;
  .icon {
    display: inline-block;
    vertical-align: middle;
    height: 1em;
    width: 1em;
  }
  .text {
    display: inline-block;
    vertical-align: middle;
  }
`;

const StyledModalP = styled.p`
  font-size: 15px;
  width: 100%;
  text-align: left;
  line-height: 16px;
`;

const StyledA = styled.a`
  color: white;
`;

const StyledLoginDiv = styled.div`
  text-align: center;
`;

const SubP = styled.div`
  margin: 5px;
  color: #cccccc;
  font-size: 16px;
  text-align: center;
  @media (max-width: 700px) {
    font-size: 15px;
  }
`;

const StyledContinueButton = styled.button`
  padding: 0;
  background-color: black;
  color: #cccccc;
  border: none;
  font-size: 16px;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
`;

const StyledButtonsDiv = styled(ButtonsDiv)`
  margin-bottom: 25px;
`;

export const StyledInputAlert = styled(Alert)`
  display: flex !important;
  align-items: center !important;
  width: min(560px, 77vw) !important;
  margin-left: -45px !important;
  background-color: rgb(211, 92, 98) !important;
  font-family: 'Radio Canada', sans-serif !important;
  color: white !important;
  font-size: 13px !important;
  margin-top: -5px !important;
  margin-bottom: 20px !important;
  height: 45px !important;

  svg {
    color: white !important;
    margin-top: -4px !important;
  }

  @media only screen and (min-width: 1200px) {
    height: 45px !important;
    font-size: 15px !important;
  }

  div {
    margin-top: 2px;
  }
`;

/** Definição de interfaces */
interface IProps {
  updateForm: Function;
  updateStep: Function;
  updateSpinner: Function;
  updateCandidateToken: Function;
}

export interface Step1Form {
  name: string;
  social_name?: string;
  email: string;
  cpf: string;
  phone: string;
}

interface Step1FormValid {
  name: boolean;
  social_name?: boolean;
  email: boolean;
  cpf: boolean;
  phone: boolean;
}

interface RegisterResponse {
  success: boolean;
  response: string;
}

const Step1 = (props: IProps) => {
  /** Variaveis iniciais */
  const navigate = useNavigate();

  const { updateForm, updateSpinner, updateStep, updateCandidateToken } = props;

  /** Estados */
  const [formInfo, setFormInfo] = useState<Step1Form>({
    name: '',
    email: '',
    cpf: '',
    phone: '',
  });

  const [formValid, setFormValid] = useState<Step1FormValid>({
    name: true,
    social_name: true,
    email: true,
    cpf: true,
    phone: true,
  });

  const [radioValue, setRadioValue] = useState<boolean>(false);

  const [checked, setChecked] = useState<boolean>(false);

  const [submitted, setSubmitted] = useState<boolean>(false);

  const [loginInvalid, setLoginInvalid] = useState<boolean>(false);

  /** Função do botão de login */
  const handleContinueButton = () => {
    navigate('/');
  };

  /** Função do checkbox de nome social */
  const handleRadioValue = (value: boolean) => {
    if (!value) delete formInfo.social_name;
    setRadioValue(value);
  };

  /** Função que controla a confirmação de leitura do edital */
  const handleChecked = () => {
    setChecked((prev: any) => !prev);
  };

  /** Função do botão de avançar */
  const handleConfirmButton = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      setFormValid((prev: any) => {
        return { ...prev, name: validateName(formInfo.name) };
      });

      if (formInfo.social_name) {
        setFormValid((prev: any) => {
          return {
            ...prev,
            social_name: validateSocialName(formInfo.social_name || ''),
          };
        });
      }

      setFormValid((prev: any) => {
        return { ...prev, email: validateEmail(formInfo.email) };
      });

      setFormValid((prev: any) => {
        return { ...prev, cpf: validateCpf(formInfo.cpf) };
      });

      setFormValid((prev: any) => {
        return { ...prev, phone: validatePhone(formInfo.phone) };
      });

      setSubmitted(true);
    },
    [formInfo],
  );

  /** Efeito para visão do componente abrir no topo da tela */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /** Efeito quando todos os campos estão válidos */
  useEffect(() => {
    const handleSubmitForm = async () => {
      try {
        const {
          data: { success, response },
        } = await randomApi.post<RegisterResponse>(
          '/candidates/register',
          formInfo,
          {
            timeout: 30000,
          },
        );

        return { success, response };
      } catch {
        return { success: false, response: 500 };
      }
    };

    const effect = async () => {
      if (submitted) {
        setLoginInvalid(false);

        let minOneFalse = false;

        for (const val in formValid) {
          if (!formValid[val as keyof Step1FormValid]) minOneFalse = true;
        }

        setSubmitted(false);

        if (!minOneFalse) {
          updateSpinner(true);
          updateForm((prev: RegisterForm) => {
            return Object.assign(prev, formInfo);
          });

          const { success, response } = await handleSubmitForm();

          updateSpinner(false);

          if (!success) {
            if (response === 500) {
              navigate('/erro-processo-seletivo');
              return;
            } else {
              setLoginInvalid(true);
            }
          } else {
            updateCandidateToken(response);
            updateStep(1);
          }
        }
      }
    };

    effect();
  }, [
    formValid,
    props,
    submitted,
    formInfo,
    navigate,
    updateSpinner,
    updateCandidateToken,
    updateStep,
    updateForm,
  ]);

  return (
    <MainForm onSubmit={handleConfirmButton}>
      <StyledIntrop>
        Boas-vindas ao processo seletivo de graduação da Faculdade XP Educação.
        Preencha todos os campos com atenção e boa sorte!
      </StyledIntrop>
      {loginInvalid ? (
        <StyledInputAlert icon={<ErrorIcon fontSize="inherit" />}>
          Já existe um cadastro para o cpf e email informados. Faça o{' '}
          <Link to="/">login</Link> para continuar no processo.
        </StyledInputAlert>
      ) : (
        <></>
      )}
      <InputTextField
        focused
        placeholder="João da Silva"
        required
        label="Nome Completo"
        type="text"
        className="inputField"
        onChange={({ target: { value } }) =>
          setFormInfo((prev) => {
            setFormValid({ ...formValid, name: true });
            return { ...prev, name: value };
          })
        }
        inputProps={{ maxLength: 150 }}
        sx={
          formValid.name
            ? {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '& fieldset': {
                    borderColor: '#7ddd00',
                  },
                },
              }
            : {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '& fieldset': {
                    borderColor: 'red',
                  },
                },
              }
        }
        value={formInfo.name}
      />
      {formValid.name ? (
        <></>
      ) : (
        <ErrorLabel id="nameError">Nome inválido</ErrorLabel>
      )}
      <SocialNameDiv>
        <Tooltip
          title={
            <div>
              Nos termos do Decreto nº 8.727/2016, nome social é a designação
              pela qual a pessoa travesti ou transexual se identifica e é
              socialmente reconhecida. Atenção: <b>NÃO</b> é sinônimo de
              apelido.
            </div>
          }
          arrow
          placement="right-end"
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                backgroundColor: '#eeeeee',
                color: '#111111',
                padding: '30px 40px',
                fontSize: '16px',
                fontWeight: '500',
                textAlign: 'justify',
              },
              '& .MuiTooltip-arrow': {
                color: '#eeeeee',
              },
            },
          }}
        >
          <InfoCircle className="icon" />
        </Tooltip>
        <FormLabel id="demo-radio-buttons-group-label">
          <span style={{ color: 'white', fontSize: '15px' }}>
            Quer se identificar com Nome Social?
          </span>
        </FormLabel>
      </SocialNameDiv>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        row
        value={radioValue}
      >
        <FormControlLabel
          control={
            <Radio
              value={false}
              sx={{
                display: 'inline-block',
                verticalAlign: 'middle',
                color: '#7ddd00',
                '&.Mui-checked': {
                  color: '#7ddd00',
                },
              }}
              onChange={() => handleRadioValue(false)}
            />
          }
          label={<span style={{ color: 'white', fontSize: '15px' }}>Não</span>}
        />
        <FormControlLabel
          control={
            <Radio
              value={true}
              sx={{
                display: 'inline-block',
                verticalAlign: 'middle',
                color: '#7ddd00',
                '&.Mui-checked': {
                  color: '#7ddd00',
                },
              }}
              onChange={() => handleRadioValue(true)}
            />
          }
          label={<span style={{ color: 'white', fontSize: '15px' }}>Sim</span>}
        />
      </RadioGroup>
      {radioValue ? (
        <>
          <InputTextField
            focused
            label="Nome Social (Opcional)"
            type="text"
            className="inputField"
            onChange={({ target: { value } }) =>
              setFormInfo((prev) => {
                setFormValid({ ...formValid, social_name: true });
                return { ...prev, social_name: formatName(value) };
              })
            }
            inputProps={{ maxLength: 150 }}
            sx={
              formValid.social_name
                ? {
                    '& .MuiOutlinedInput-root': {
                      color: 'white !important',
                      '&.Mui-focused fieldset': {
                        borderColor: '#7ddd00',
                      },
                      '&:hover fieldset': {
                        borderColor: '#7ddd00',
                      },
                      '& fieldset': {
                        borderColor: '#7ddd00',
                      },
                    },
                  }
                : {
                    '& .MuiOutlinedInput-root': {
                      color: 'white !important',
                      '&.Mui-focused fieldset': {
                        borderColor: 'red',
                      },
                      '&:hover fieldset': {
                        borderColor: 'red',
                      },
                      '& fieldset': {
                        borderColor: 'red',
                      },
                    },
                  }
            }
          >
            {formInfo.social_name}
          </InputTextField>
          {formValid.social_name ? (
            <></>
          ) : (
            <ErrorLabel id="socialNameError">Nome inválido</ErrorLabel>
          )}
        </>
      ) : null}
      <InputTextField
        focused
        required
        label="Email"
        placeholder="candidato@xpeducacao.com.br"
        type="text"
        className="inputField"
        onChange={({ target: { value } }) =>
          setFormInfo((prev) => {
            setFormValid({ ...formValid, email: true });
            return { ...prev, email: formatEmail(value) };
          })
        }
        inputProps={{ maxLength: 200 }}
        sx={
          formValid.email
            ? {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '& fieldset': {
                    borderColor: '#7ddd00',
                  },
                },
              }
            : {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '& fieldset': {
                    borderColor: 'red',
                  },
                },
              }
        }
      >
        {formInfo.email}
      </InputTextField>
      <StyledHintDiv>
        <RiAlertLine className="icon" />{' '}
        <p className="text">
          Seu email <u>NÃO</u> poderá ser alterado mais tarde.
        </p>
      </StyledHintDiv>
      {formValid.email ? (
        <></>
      ) : (
        <ErrorLabel id="emailError">Email inválido</ErrorLabel>
      )}
      <InputTextField
        focused
        placeholder="000.000.000-00"
        required
        label="CPF"
        type="text"
        className="inputField"
        onChange={({ target: { value } }) =>
          setFormInfo((prev) => {
            setFormValid({ ...formValid, cpf: true });
            return { ...prev, cpf: value };
          })
        }
        value={formInfo.cpf}
        InputProps={{
          inputComponent: CpfMask as any,
        }}
        sx={
          formValid.cpf
            ? {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '& fieldset': {
                    borderColor: '#7ddd00',
                  },
                },
              }
            : {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '& fieldset': {
                    borderColor: 'red',
                  },
                },
              }
        }
      />
      {formValid.cpf ? (
        <></>
      ) : (
        <ErrorLabel id="cpfError">CPF inválido</ErrorLabel>
      )}
      <InputTextField
        focused
        placeholder="(00) 00000-0000"
        required
        label="Telefone"
        type="tel"
        className="inputField"
        onChange={({ target: { value } }) =>
          setFormInfo((prev) => {
            setFormValid({ ...formValid, phone: true });
            return { ...prev, phone: value };
          })
        }
        value={formInfo.phone}
        InputProps={{ inputComponent: PhoneMask as any }}
        sx={
          formValid.phone
            ? {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '& fieldset': {
                    borderColor: '#7ddd00',
                  },
                },
              }
            : {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '& fieldset': {
                    borderColor: 'red',
                  },
                },
              }
        }
      />
      {formValid.phone ? (
        <></>
      ) : (
        <ErrorLabel id="phoneError">Telefone inválido</ErrorLabel>
      )}
      <FormControlLabel
        sx={{
          marginBottom: '8px',
        }}
        control={
          <Checkbox
            sx={{
              display: 'inline-block',
              verticalAlign: 'middle',
              color: '#7ddd00',
              '&.Mui-checked': {
                color: '#7ddd00',
              },
            }}
            onChange={handleChecked}
          />
        }
        label={
          <StyledModalP>
            Declaro que li e estou de acordo com os termos presentes no{' '}
            <StyledA
              href="https://irp.cdn-website.com/35665dd9/files/uploaded/2%C2%BA%20Edital%20Processo%20Seletivo%20%20-%20Faculdade%20XP%20Educa%C3%A7%C3%A3o%20-IGTI%20%2001.2023_130123.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <u>edital publicado</u>
            </StyledA>
            .
          </StyledModalP>
        }
      />
      <StyledButtonsDiv>
        <ConfirmButton type="submit" disabled={!checked}>
          AVANÇAR
        </ConfirmButton>
      </StyledButtonsDiv>
      <StyledLoginDiv>
        <SubP>Já concluí algumas etapas.</SubP>
        <StyledContinueButton type="button" onClick={handleContinueButton}>
          Quero continuar
        </StyledContinueButton>
      </StyledLoginDiv>
    </MainForm>
  );
};

export default Step1;
