import axios from 'axios';

const getCep = async (cep: string) => {
  const resp = await axios
    .get(`https://viacep.com.br/ws/${cep}/json/`, { timeout: 5000 })
    .then((resp) => {
      if (resp.data) {
        return resp.data;
      }
      return false;
    })
    .catch(() => {
      return false;
    });

  return resp;
};

export default getCep;
