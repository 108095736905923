import styled from 'styled-components';

const ButtonsDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  margin-top: 8px !important;
  height: 50px;
`;

export default ButtonsDiv;
