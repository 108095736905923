import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmButton from '../../components/confirm-button';
import ButtonsDiv from '../../components/buttons-div';
import MainForm from '../../components/main-form';
import BackButton from '../../components/back-button';
import styled from 'styled-components';
import { FormControl, MenuItem, Select } from '@mui/material';
import ErrorLabel from '../../components/error-label';
import { validateStringSelect } from '../../shared/validators';
import StyledLabel from '../../components/styled-label';
import { randomApi } from '../../shared/random';
import { headers } from '../../shared/config';
import { RegisterForm } from '../Register';
import { StyledInputAlert } from './step3';
import ErrorIcon from '@mui/icons-material/Error';

/** Definição de componentes */
const StyledMenuItem = styled(MenuItem)`
  max-width: 100% !important;
  overflow: auto !important;
  font-size: 14px !important;
`;

const StyledSelectLabel = styled(StyledLabel)`
  font-size: 15px;
  padding: 0 5px;
  text-align: justify;
  margin-top: 15px;
`;

const FirstStyledSelectLabel = styled(StyledSelectLabel)`
  margin-top: 0px;
`;

const StyledConfirmButton = styled(ConfirmButton)`
  width: 200px;
`;

const StyledP = styled.p`
  text-align: center;
  font-size: 20px;
  color: white;
  margin-bottom: 30px;
  font-weight: 400 !important;
`;

const StyledButtonDiv = styled(ButtonsDiv)`
  margin-top: 0px !important;
`;

const StyledSelect = styled(Select)`
  margin-bottom: 30px;
  max-width: 500px;
  margin-top: 6px;
`;

const StyledErrorLabel = styled(ErrorLabel)`
  margin-top: -25px;
  margin-bottom: 20px;
  padding-left: 3px;
`;

/** Definição de interfaces */
interface IProps {
  updateForm: Function;
  candidateToken: string;
  updateStep: Function;
  updateSpinner: Function;
  form: RegisterForm;
}

interface Step4Form {
  reason: string;
  courseAttribute: string;
  marketSituation: string;
  occupationArea: string;
  salary: string;
  graduation: string;
  specialization: string;
}

interface Step4FormValid {
  reason: boolean;
  courseAttribute: boolean;
  marketSituation: boolean;
  occupationArea: boolean;
  salary: boolean;
  graduation: boolean;
  specialization: boolean;
}

interface UpdateQuizResponse {
  success: boolean;
}

const Step4 = (props: IProps) => {
  /** Variaveis iniciais */
  const navigate = useNavigate();

  const { updateForm, candidateToken, updateStep, updateSpinner, form } = props;

  const reasons = [
    'Ser da XP',
    'Ser uma porta de entrada para o mercado financeiro',
    'Ser uma porta de entrada para a carreira tech',
    'Ser gratuito',
    'Ser remoto',
    'Ser um ensino aplicado ao mercado de trabalho',
    'Aprender com referências de mercado',
  ];

  const courseAttributes = [
    'Aumento salarial',
    'Novo emprego na sua área de formação',
    'Conseguir primeiro emprego',
    'Ser promovido',
    'Ser aprovado em concurso público',
    'Realizar transição de carreira',
    'Outro',
  ];

  const occupationAreas = [
    'Tecnologia',
    'Mercado Financeiro',
    'Saúde',
    'Engenharia e construção',
    'Outro',
    'Não trabalho',
  ];

  const marketSituations = [
    'Estou desempregado(a)',
    'Trabalho, mas busco outro emprego em nova área',
    'Trabalho, mas busco outro emprego na mesma área',
    'Trabalho e estou contente onde estou',
    'Sou funcionário público',
    'Sou estudante',
    'Outro',
  ];

  const salaries = [
    'Entre 0 e 1 salário',
    'Entre 1 e 3 salários',
    'Entre 3 e 7 salários',
    'Entre 7 e 10 salários',
    'Mais de 10 salários',
  ];

  const graduations = [
    'Saúde',
    'Tecnologia',
    'Finanças',
    'Comunicação',
    'Engenharia',
    'Direito',
    'Outras',
    'Não possuo',
  ];

  const specializations = [
    'Saúde',
    'Tecnologia',
    'Finanças',
    'Comunicação',
    'Engenharia',
    'Direito',
    'Outras',
    'Não possuo',
  ];

  /** Estados */
  const [formInfo, setFormInfo] = useState<Step4Form>({
    reason: '0',
    courseAttribute: '0',
    marketSituation: '0',
    occupationArea: '0',
    salary: '0',
    graduation: '0',
    specialization: '0',
  });

  const [formValid, setFormValid] = useState<Step4FormValid>({
    reason: true,
    courseAttribute: true,
    marketSituation: true,
    occupationArea: true,
    salary: true,
    graduation: true,
    specialization: true,
  });

  const [submitted, setSubmitted] = useState<boolean>(false);

  const [firstIteration, setFirstIteration] = useState<boolean>(true);

  const [errorAlert, setErrorAlert] = useState<boolean>(false);

  /** Efeito para recuperar valores salvos no form geral */
  useEffect(() => {
    if (firstIteration) {
      Object.keys(formInfo).map((key: any) => {
        if (form.hasOwnProperty(key)) {
          if (form[key as keyof RegisterForm]) {
            setFormInfo(() => {
              return Object.assign(formInfo, {
                [key]: form[key as keyof RegisterForm],
              });
            });
          }
        }
        return null;
      });
      setFirstIteration(false);
    }
  }, [formInfo, form, firstIteration]);

  /** Função do botão de voltar */
  const handleBackButton = () => {
    updateStep(2);
  };

  /** Função do botão de avançar */
  const handleConfirmButton = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      setFormValid((prev) => {
        return { ...prev, reason: validateStringSelect(formInfo.reason) };
      });

      setFormValid((prev) => {
        return {
          ...prev,
          courseAttribute: validateStringSelect(formInfo.courseAttribute),
        };
      });

      setFormValid((prev) => {
        return {
          ...prev,
          marketSituation: validateStringSelect(formInfo.marketSituation),
        };
      });

      setFormValid((prev) => {
        return {
          ...prev,
          occupationArea: validateStringSelect(formInfo.occupationArea),
        };
      });

      setFormValid((prev) => {
        return {
          ...prev,
          salary: validateStringSelect(formInfo.salary),
        };
      });

      setFormValid((prev) => {
        return {
          ...prev,
          graduation: validateStringSelect(formInfo.graduation),
        };
      });

      setFormValid((prev) => {
        return {
          ...prev,
          specialization: validateStringSelect(formInfo.specialization),
        };
      });

      setSubmitted(true);
    },
    [formInfo],
  );

  /** Efeito para visão do componente abrir no topo da tela */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /** Efeito quando todos os campos estão válidos */
  useEffect(() => {
    const updateQuiz = async () => {
      try {
        const formInfoValues = Object.values(formInfo);
        const answersList = formInfoValues.map((value) => String(value));

        const {
          data: { success },
        } = await randomApi.post<UpdateQuizResponse>(
          '/quizzes/',
          {
            answers: answersList,
          },
          {
            headers: headers(candidateToken),
            timeout: 30000,
          },
        );

        updateSpinner(false);

        if (!success) {
          navigate('/erro-processo-seletivo');
          return;
        }

        updateStep(4);
      } catch {
        updateSpinner(false);
        navigate('/erro-processo-seletivo');
        return;
      }
    };

    const effect = async () => {
      if (submitted) {
        setErrorAlert(false);
        let minOneFalse = false;
        for (const val in formValid) {
          if (!formValid[val as keyof Step4FormValid]) minOneFalse = true;
        }

        setSubmitted(false);

        if (!minOneFalse) {
          updateSpinner(true);
          updateForm((prev: RegisterForm) => {
            return Object.assign(prev, formInfo);
          });
          await updateQuiz();
        } else {
          window.scrollTo(0, 0);
          setErrorAlert(true);
        }
      }
    };

    effect();
  }, [
    formInfo,
    formValid,
    navigate,
    submitted,
    candidateToken,
    updateSpinner,
    updateStep,
    updateForm,
  ]);

  return (
    <>
      <MainForm onSubmit={handleConfirmButton}>
        <StyledP>
          Responda a pesquisa abaixo! É meramente informativa. Desejamos somente
          conhecer o perfil dos nossos inscritos.
        </StyledP>

        {errorAlert ? (
          <StyledInputAlert icon={<ErrorIcon fontSize="inherit" />}>
            Por favor, revise os valores dos campos
          </StyledInputAlert>
        ) : (
          <></>
        )}

        <FormControl>
          <FirstStyledSelectLabel>
            Selecione o motivo mais importante que levou você a escolher a XP
            Educação *
          </FirstStyledSelectLabel>
          <StyledSelect
            value={formInfo.reason}
            onChange={({ target: { value } }) =>
              setFormInfo((prev) => {
                setFormValid({ ...formValid, reason: true });
                return { ...prev, reason: value as string };
              })
            }
            sx={
              formValid.reason
                ? {
                    '.MuiSelect-select': {
                      color: 'white !important',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                      borderWidth: '2px !important',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                      borderWidth: '2px !important',
                    },
                  }
                : {
                    '.MuiSelect-select': {
                      color: 'white',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red !important',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red',
                      borderWidth: '2px !important',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red',
                      borderWidth: '2px !important',
                    },
                  }
            }
          >
            <StyledMenuItem disabled value={'0'}>
              <em>Selecione</em>
            </StyledMenuItem>
            {reasons.map((value) => (
              <StyledMenuItem value={value} key={value}>
                {value}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </FormControl>
        {formValid.reason ? (
          <></>
        ) : (
          <StyledErrorLabel id="reasonError">
            É necessário selecionar uma opção
          </StyledErrorLabel>
        )}

        <FormControl>
          <StyledSelectLabel>
            Selecione um atributo que você gostaria de conquistar ao final do
            curso *
          </StyledSelectLabel>
          <StyledSelect
            value={formInfo.courseAttribute}
            onChange={({ target: { value } }) =>
              setFormInfo((prev) => {
                setFormValid({ ...formValid, courseAttribute: true });
                return { ...prev, courseAttribute: value as string };
              })
            }
            sx={
              formValid.courseAttribute
                ? {
                    '.MuiSelect-select': {
                      color: 'white !important',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                      borderWidth: '2px !important',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                      borderWidth: '2px !important',
                    },
                  }
                : {
                    '.MuiSelect-select': {
                      color: 'white',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red !important',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red',
                      borderWidth: '2px !important',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red',
                      borderWidth: '2px !important',
                    },
                  }
            }
          >
            <StyledMenuItem disabled value={'0'}>
              <em>Selecione</em>
            </StyledMenuItem>
            {courseAttributes.map((value) => (
              <StyledMenuItem value={value} key={value}>
                {value}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </FormControl>
        {formValid.courseAttribute ? (
          <></>
        ) : (
          <StyledErrorLabel id="courseAttributeError">
            É necessário selecionar uma opção
          </StyledErrorLabel>
        )}

        <FormControl>
          <StyledSelectLabel>
            Qual é a sua situação no mercado? *
          </StyledSelectLabel>
          <StyledSelect
            value={formInfo.marketSituation}
            onChange={({ target: { value } }) =>
              setFormInfo((prev) => {
                setFormValid({ ...formValid, marketSituation: true });
                return { ...prev, marketSituation: value as string };
              })
            }
            sx={
              formValid.marketSituation
                ? {
                    '.MuiSelect-select': {
                      color: 'white !important',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                      borderWidth: '2px !important',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                      borderWidth: '2px !important',
                    },
                  }
                : {
                    '.MuiSelect-select': {
                      color: 'white',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red !important',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red',
                      borderWidth: '2px !important',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red',
                      borderWidth: '2px !important',
                    },
                  }
            }
          >
            <StyledMenuItem disabled value={'0'}>
              <em>Selecione</em>
            </StyledMenuItem>
            {marketSituations.map((value) => (
              <StyledMenuItem value={value} key={value}>
                {value}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </FormControl>
        {formValid.marketSituation ? (
          <></>
        ) : (
          <StyledErrorLabel id="marketSituationError">
            É necessário selecionar uma opção
          </StyledErrorLabel>
        )}

        <FormControl>
          <StyledSelectLabel>
            Em qual setor você trabalha atualmente? *
          </StyledSelectLabel>
          <StyledSelect
            value={formInfo.occupationArea}
            onChange={({ target: { value } }) =>
              setFormInfo((prev) => {
                setFormValid({ ...formValid, occupationArea: true });
                return { ...prev, occupationArea: value as string };
              })
            }
            sx={
              formValid.occupationArea
                ? {
                    '.MuiSelect-select': {
                      color: 'white !important',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                      borderWidth: '2px !important',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                      borderWidth: '2px !important',
                    },
                  }
                : {
                    '.MuiSelect-select': {
                      color: 'white',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red !important',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red',
                      borderWidth: '2px !important',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red',
                      borderWidth: '2px !important',
                    },
                  }
            }
          >
            <StyledMenuItem disabled value={'0'}>
              <em>Selecione</em>
            </StyledMenuItem>
            {occupationAreas.map((value) => (
              <StyledMenuItem value={value} key={value}>
                {value}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </FormControl>
        {formValid.occupationArea ? (
          <></>
        ) : (
          <StyledErrorLabel id="occupationAreaError">
            É necessário selecionar uma opção
          </StyledErrorLabel>
        )}

        <FormControl>
          <StyledSelectLabel>Qual sua renda bruta mensal? *</StyledSelectLabel>
          <StyledSelect
            value={formInfo.salary}
            onChange={({ target: { value } }) =>
              setFormInfo((prev) => {
                setFormValid({ ...formValid, salary: true });
                return { ...prev, salary: value as string };
              })
            }
            sx={
              formValid.salary
                ? {
                    '.MuiSelect-select': {
                      color: 'white !important',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                      borderWidth: '2px !important',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                      borderWidth: '2px !important',
                    },
                  }
                : {
                    '.MuiSelect-select': {
                      color: 'white',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red !important',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red',
                      borderWidth: '2px !important',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red',
                      borderWidth: '2px !important',
                    },
                  }
            }
          >
            <StyledMenuItem disabled value={'0'}>
              <em>Selecione</em>
            </StyledMenuItem>
            {salaries.map((value) => (
              <StyledMenuItem value={value} key={value}>
                {value}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </FormControl>
        {formValid.salary ? (
          <></>
        ) : (
          <StyledErrorLabel id="salaryError">
            É necessário selecionar uma opção
          </StyledErrorLabel>
        )}

        <FormControl>
          <StyledSelectLabel>
            Você possui graduação em alguma dessas áreas? *
          </StyledSelectLabel>
          <StyledSelect
            value={formInfo.graduation}
            onChange={({ target: { value } }) =>
              setFormInfo((prev) => {
                setFormValid({ ...formValid, graduation: true });
                return { ...prev, graduation: value as string };
              })
            }
            sx={
              formValid.graduation
                ? {
                    '.MuiSelect-select': {
                      color: 'white !important',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                      borderWidth: '2px !important',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                      borderWidth: '2px !important',
                    },
                  }
                : {
                    '.MuiSelect-select': {
                      color: 'white',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red !important',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red',
                      borderWidth: '2px !important',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red',
                      borderWidth: '2px !important',
                    },
                  }
            }
          >
            <StyledMenuItem disabled value={'0'}>
              <em>Selecione</em>
            </StyledMenuItem>
            {graduations.map((value) => (
              <StyledMenuItem value={value} key={value}>
                {value}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </FormControl>
        {formValid.graduation ? (
          <></>
        ) : (
          <StyledErrorLabel id="graduationError">
            É necessário selecionar uma opção
          </StyledErrorLabel>
        )}

        <FormControl>
          <StyledSelectLabel>
            Você possui pós-graduação, especialização, mestrado ou doutorado em
            alguma dessas áreas? *
          </StyledSelectLabel>
          <StyledSelect
            value={formInfo.specialization}
            onChange={({ target: { value } }) =>
              setFormInfo((prev) => {
                setFormValid({ ...formValid, specialization: true });
                return { ...prev, specialization: value as string };
              })
            }
            sx={
              formValid.specialization
                ? {
                    '.MuiSelect-select': {
                      color: 'white !important',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                      borderWidth: '2px !important',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#7ddd00',
                      borderWidth: '2px !important',
                    },
                  }
                : {
                    '.MuiSelect-select': {
                      color: 'white',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red !important',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red',
                      borderWidth: '2px !important',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'red',
                      borderWidth: '2px !important',
                    },
                  }
            }
          >
            <StyledMenuItem disabled value={'0'}>
              <em>Selecione</em>
            </StyledMenuItem>
            {specializations.map((value) => (
              <StyledMenuItem value={value} key={value}>
                {value}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </FormControl>
        {formValid.specialization ? (
          <></>
        ) : (
          <StyledErrorLabel id="specializationError">
            É necessário selecionar uma opção
          </StyledErrorLabel>
        )}

        <StyledButtonDiv>
          <StyledConfirmButton type="submit">AVANÇAR</StyledConfirmButton>
          <BackButton type="button" onClick={handleBackButton}>
            VOLTAR
          </BackButton>
        </StyledButtonDiv>
      </MainForm>
    </>
  );
};

export default Step4;
