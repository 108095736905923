import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainForm from '../../components/main-form';
import InputTextField from '../../components/input-text-field';
import ButtonsDiv from '../../components/buttons-div';
import ConfirmButton from '../../components/confirm-button';
import { randomApi } from '../../shared/random';
import { headers } from '../../shared/config';
import styled from 'styled-components';
import { Alert } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { RegisterForm } from '../Register';

/** Definição de componentes */
const StyledButton = styled(ConfirmButton)`
  width: min(180px, 90vw);
  height: 50px !important;
`;

const StyledButtonsDiv = styled(ButtonsDiv)`
  flex-direction: column;
  align-items: flex-end;
  row-gap: 10px;
`;

const StyledInputAlert = styled(Alert)`
  display: flex !important;
  align-items: center !important;
  width: min(500px, 77vw) !important;
  margin-left: -17px !important;
  background-color: rgb(211, 92, 98) !important;
  font-family: 'Radio Canada', sans-serif !important;
  color: white !important;
  font-size: 13px !important;
  margin-top: -5px !important;
  margin-bottom: 15px !important;
  height: 45px !important;

  svg {
    color: white !important;
    margin-top: -4px !important;
  }

  @media only screen and (min-width: 1200px) {
    height: 35px !important;
    font-size: 15px !important;
  }

  div {
    margin-top: 2px;
  }
`;

const GreenInputAlert = styled(StyledInputAlert)`
  background-color: #7ddd00 !important;
`;

/** Definição de interfaces */
interface IProps {
  candidateToken: string;
  updateStep: Function;
  updateSpinner: Function;
  form: RegisterForm;
}

interface Step2Form {
  code: string;
}

interface Step2FormValid {
  code: boolean;
}

interface VerificateResponse {
  success: boolean;
  response: string;
}

interface ResendResponse {
  success: boolean;
  response: string;
}

const Step2 = (props: IProps) => {
  /** Variaveis iniciais */
  const navigate = useNavigate();

  const { candidateToken, updateStep, updateSpinner } = props;

  /** Estados */
  const [formInfo, setFormInfo] = useState<Step2Form>({
    code: '',
  });

  const [formValid, setFormValid] = useState<Step2FormValid>({
    code: true,
  });

  const [submitted, setSubmitted] = useState<boolean>(false);

  const [errorAlert, setErrorAlert] = useState<string | null>(null);

  const [alert, setAlert] = useState<boolean>(false);

  /** Função do botão de avançar */
  const handleConfirmButton = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      setSubmitted(true);
    },
    [],
  );

  /** Efeito para visão do componente abrir no topo da tela */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /** Efeito quando todos os campos estão válidos */
  useEffect(() => {
    const handleSubmitForm = async () => {
      try {
        const {
          data: { success, response },
        } = await randomApi.post<VerificateResponse>(
          '/candidates/verificate',
          formInfo,
          {
            headers: headers(candidateToken),
            timeout: 30000,
          },
        );

        return { success, response };
      } catch {
        return { success: false, response: 500 };
      }
    };

    const effect = async () => {
      if (submitted) {
        setAlert(false);
        setErrorAlert(null);

        let minOneFalse = false;

        for (const val in formValid) {
          if (!formValid[val as keyof Step2FormValid]) minOneFalse = true;
        }

        setSubmitted(false);

        if (!minOneFalse) {
          updateSpinner(true);

          const { success, response } = await handleSubmitForm();

          updateSpinner(false);

          if (!success) {
            if (response === 500) {
              navigate('/erro-processo-seletivo');
              return;
            } else if (
              response === 'Candidate not found' ||
              response === 'Already verified'
            ) {
              navigate('/erro-processo-seletivo');
              return;
            } else {
              setErrorAlert('Código inválido');
              setFormInfo({ code: '' });
            }
          } else {
            updateStep(2);
          }
        }
      }
    };

    effect();
  }, [
    formValid,
    props,
    submitted,
    formInfo,
    navigate,
    candidateToken,
    updateSpinner,
    updateStep,
  ]);

  /** Função do botão de reenvio */
  const handleResendCode = async () => {
    updateSpinner(true);

    setAlert(false);
    setErrorAlert(null);

    try {
      const {
        data: { success, response },
      } = await randomApi.post<ResendResponse>(
        '/candidates/resend_verification',
        formInfo,
        {
          headers: headers(candidateToken),
          timeout: 30000,
        },
      );

      updateSpinner(false);

      if (!success) {
        if (response === 'Resend limit time reached') {
          setErrorAlert('Máximo de 1 reenvio por minuto');
        } else if (response === 'Max resends reached') {
          setErrorAlert('Máximo de reenvios atingido');
        } else {
          navigate('/erro-processo-seletivo');
          return;
        }
      } else {
        setAlert(true);
      }
    } catch {
      updateSpinner(false);
      navigate('/erro-processo-seletivo');
      return;
    }
  };

  return (
    <MainForm onSubmit={handleConfirmButton}>
      {errorAlert ? (
        <StyledInputAlert icon={<ErrorIcon fontSize="inherit" />}>
          {errorAlert}
        </StyledInputAlert>
      ) : (
        <></>
      )}
      {alert ? (
        <GreenInputAlert icon={<ErrorIcon fontSize="inherit" />}>
          Código reenviado
        </GreenInputAlert>
      ) : (
        <></>
      )}
      <InputTextField
        focused
        required
        label="Código de confirmação"
        type="text"
        className="inputField"
        onChange={({ target: { value } }) =>
          setFormInfo((prev) => {
            setFormValid({ ...formValid, code: true });
            return { ...prev, code: value };
          })
        }
        inputProps={{ maxLength: 6 }}
        sx={
          formValid.code
            ? {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover fieldset': {
                    borderColor: '#7ddd00',
                  },
                  '& fieldset': {
                    borderColor: '#7ddd00',
                  },
                },
              }
            : {
                '& .MuiOutlinedInput-root': {
                  color: 'white !important',
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '& fieldset': {
                    borderColor: 'red',
                  },
                },
              }
        }
        value={formInfo.code}
      />
      <StyledButtonsDiv>
        <StyledButton type="submit" disabled={formInfo.code.length !== 6}>
          AVANÇAR
        </StyledButton>
        <StyledButton onClick={handleResendCode}>REENVIAR CÓDIGO</StyledButton>
      </StyledButtonsDiv>
    </MainForm>
  );
};

export default Step2;
