import styled from 'styled-components';

import logo from 'shared/assets/images/faculdade_xpe_branco.png';

const FooterElement = styled.footer`
  // background-color: rgb(55, 55, 55);
  background-color: #414141;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  height: 55px;
  padding: 5px 0;
  margin-top: 60px;
  /* background-color: red; */

  @media (max-width: 514px) {
    height: 70px;
  }
`;

const FooterLogoImg = styled.img`
  height: 70px;
  object-fit: contain;
  margin-top: -16px;
  margin-bottom: -12px;
  /* margin: -12px 0; */
  /* background-color: blue; */
`;

const FooterP = styled.p`
  color: white;
  font-size: 13px;
  height: 15px;
  // background-color: green;
  text-align: center;
  margin: auto;
  line-height: 15px;

  @media (max-width: 514px) {
    height: 30px;
  }
`;

const Footer = () => {
  return (
    <FooterElement>
      <FooterLogoImg src={logo} alt="Logo XP Educação" />
      <FooterP>Todos os direitos reservados | XP Educação © 2023</FooterP>
    </FooterElement>
  );
};

export default Footer;
