import Joi from 'joi';

export const validateName = (name: string) => {
  try {
    name = name.trim();
    return true;

    // if (
    //   /^(?:[\u00c0-\u01ffa-zA-Z'-]){2,}(?:\s[\u00c0-\u01ffa-zA-Z'-]{1,})+$/i.test(
    //     name,
    //   )
    // ) {
    //   return true;
    // }

    // return false;
  } catch (e) {
    return false;
  }
};

export const validateSocialName = (name: string) => {
  try {
    name = name.trim();

    if (name === '') return true;

    if (
      /^(?:[\u00c0-\u01ffa-zA-Z'-]){2,}(?:\s[\u00c0-\u01ffa-zA-Z'-]{1,})+$/i.test(
        name,
      )
    ) {
      return true;
    }

    return false;
  } catch (e) {
    return false;
  }
};

export const validateEmail = (email: string) => {
  try {
    Joi.assert(email, Joi.string().email({ tlds: false }));
    return true;
  } catch (err) {
    return false;
  }
};

export const validateCpf = (cpf: string) => {
  try {
    const valCpf = cpf.replace('.', '').replace('.', '').replace('-', '');
    let soma = 0;
    let resto;

    if (
      valCpf === '00000000000' ||
      valCpf === '11111111111' ||
      valCpf === '22222222222' ||
      valCpf === '33333333333' ||
      valCpf === '44444444444' ||
      valCpf === '55555555555' ||
      valCpf === '66666666666' ||
      valCpf === '77777777777' ||
      valCpf === '88888888888' ||
      valCpf === '99999999999' ||
      valCpf === '' ||
      valCpf.length !== 11
    )
      return false;

    for (let i = 1; i <= 9; i++)
      soma = soma + parseInt(valCpf.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(valCpf.substring(9, 10))) return false;
    soma = 0;

    for (let i = 1; i <= 10; i++)
      soma = soma + parseInt(valCpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(valCpf.substring(10, 11))) return false;

    return true;
  } catch {
    return false;
  }
};

export const validatePhone = (phone: string) => {
  try {
    if (/\(\d{2,}\) \d{4,}-\d{4}/g.test(phone)) {
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  }
};

export const validateHomePhone = (phone: string) => {
  try {
    if (/\(\d{2,}\) \d{4}-\d{4}/g.test(phone)) {
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  }
};

export const validateOption = (option: boolean | null) => {
  return !(option === null);
};

export const validateSelect = (option: number) => {
  return option !== 0;
};

export const validateStringSelect = (option: string) => {
  return option !== '0';
};

export const validateSecondCourse = (
  firstCourse: string,
  secondCourse: string,
  coursesList: string[][],
) => {
  if (secondCourse === '0') return true;

  for (let i = 0; i < coursesList.length; i++) {
    const courses = coursesList[i];
    if (courses.includes(firstCourse) && courses.includes(secondCourse))
      return true;
  }

  return false;
};

export const validateRg = (rg: string) => {
  try {
    Number(rg);
    return true;
  } catch {
    return false;
  }
};

export const validateImg = (img: any) => {
  if (
    img.type !== 'application/pdf' &&
    img.type !== 'image/png' &&
    img.type !== 'image/jpeg'
  )
    return false;

  if (img.name.length > 80) return false;

  const fileExtension = img.name.split('.')[1] as string;

  if (!['png', 'jpg', 'jpeg', 'pdf'].includes(fileExtension.toLowerCase()))
    return false;

  if (img) {
    return img.size / 1024 / 1024 <= 4;
  }

  return false;
};

export const validateImg1MB = (img: any) => {
  if (img.type !== 'image/png' && img.type !== 'image/jpeg') return false;

  if (img.name.length > 80) return false;

  if (img) {
    return img.size / 1024 / 1024 <= 1;
  }

  return false;
};

export const validateVoterRegistration = (voterRegistration: string) => {
  return voterRegistration.length >= 13;
};

export const validateBirthday = (birthday: string) => {
  try {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;

    if (birthday.match(regex) === null) {
      return false;
    }

    const [day, month, year] = birthday.split('/');

    // verificar se o mes está entre 1 e 12
    if (Number(month) < 1 || Number(month) > 12) {
      return false;
    }

    // determinar o numero maximo de dias permitidos para o mes
    let maxDays;
    if ([1, 3, 5, 7, 8, 10, 12].includes(Number(month))) {
      maxDays = 31;
    } else if ([4, 6, 9, 11].includes(Number(month))) {
      maxDays = 30;
    } else {
      // verificar se eh um ano bissexto
      if (
        Number(year) % 4 === 0 &&
        (Number(year) % 100 !== 0 || Number(year) % 400 === 0)
      ) {
        maxDays = 29;
      } else {
        maxDays = 28;
      }
    }

    // verificar se o dia eh valido para o mes
    if (Number(day) < 1 || Number(day) > maxDays) {
      return false;
    }

    // 👇️ format Date string as `yyyy-mm-dd`
    const date = new Date(
      Date.UTC(Number(year), Number(month) - 1, Number(day)),
    );

    const timestamp = date.getTime();

    if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
      return false;
    }

    const actualDate = new Date();
    const actualYear = actualDate.getUTCFullYear();
    const actualMonth = actualDate.getUTCMonth();
    const actualDay = actualDate.getUTCDate();

    if (actualYear - date.getUTCFullYear() >= 19) {
      return 18;
    } else if (actualYear - date.getUTCFullYear() <= 17) {
      return 17;
    } else {
      if (actualMonth > date.getUTCMonth()) return 18;
      else if (actualMonth < date.getUTCMonth()) return 17;
      else {
        if (actualDay > date.getUTCDate()) return 17;
        else return 18;
      }
    }
  } catch {
    return false;
  }
};

export const validateCep = (cep: string) => {
  const regex = /^\d{5}-\d{3}$/;

  if (cep.match(regex) === null) {
    return false;
  }

  return true;
};

export const validateMonthYear = (myDate: string) => {
  // const regex = /^\d{2}\/\d{4}$/;
  const regex = /^0[1-9]|1[0-2]\/[1-2]\d{3}$/;

  if (myDate.match(regex) === null) {
    return false;
  }

  const [month, year] = myDate.split('/');

  // 👇️ format Date string as `yyyy-mm-dd`
  // const isoFormattedStr = `${year}-${month}-${1}`;

  const date = new Date(Date.UTC(Number(year), Number(month) - 1, 1));

  const timestamp = date.getTime();

  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    return false;
  }

  return date;
};

export const validateEnemSubscription = (enemSubscription: string) => {
  try {
    if (enemSubscription.length === 12) {
      const value = Number(enemSubscription);
      if (value) return true;
    }
    return false;
  } catch {
    return false;
  }
};

export const validateTestScore = (enemSubscription: string) => {
  try {
    const score = Number(enemSubscription);

    if (score >= 0 && score <= 1000) return true;

    return false;
  } catch {
    return false;
  }
};

export const validateAddressNumber = (addressNumber: string) => {
  try {
    Number(addressNumber);
    return true;
  } catch {
    return false;
  }
};

export const validateCheckboxList = (checkboxList: string[]) => {
  return checkboxList.length > 0;
};

// export const validateCheckbox = (checkbox: boolean) => {
//   console.log(checkbox);
// };

export const validateTextArea = (text: string, max_caracteres: number) => {
  return text.length <= max_caracteres;
};

export const validateCid = (value: string) => {
  return value.length > 0 && value.length <= 15;
};

export const validateModality = (
  value: string,
  genre: string,
  ethnicGroup: string,
) => {
  try {
    if (value === 'Ampla Concorrência') return true;
    else if (
      value === 'Reserva de vagas para mulheres' &&
      (genre.includes('Mulher cisgênera') ||
        genre.includes('Mulher trans') ||
        genre.includes('Não Binário'))
    )
      return true;
    else if (
      value ===
        'Reserva de vagas étnico-raciais para pretos(as) e pardos(as)' &&
      (ethnicGroup.includes('Parda') || ethnicGroup.includes('Preta'))
    )
      return true;
    else return false;
  } catch {
    return false;
  }
};

export const ValidateText = (text: string) => {
  return text.length >= 250 && text.length <= 3000;
};

export const ValidateLengthMax560 = (text: string) => {
  return text && text.length <= 560;
};

export const ValidateLengthMax80 = (text: string) => {
  return text && text.length <= 80;
};

export const validateGraduationYear = (year: number) => {
  return year >= 1950 && year <= 2023;
};
