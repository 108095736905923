import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmButton from '../../components/confirm-button';
import ButtonsDiv from '../../components/buttons-div';
import ErrorLabel from '../../components/error-label';
import MainForm from '../../components/main-form';
import BackButton from '../../components/back-button';
import { FormControl, MenuItem, Select } from '@mui/material';
import styled from 'styled-components';
// import { validateModality, validateSelect } from './validators';
import { validateSelect } from '../../shared/validators';
import StyledLabel from '../../components/styled-label';
import { headers } from '../../shared/config';
import { randomApi } from '../../shared/random';
import { RegisterForm } from '../Register';
import ErrorIcon from '@mui/icons-material/Error';
import { StyledInputAlert } from './step3';

const StyledMenuItem = styled(MenuItem)`
  max-width: 100% !important;
  overflow: auto !important;
  font-size: 14px !important;
`;

const StyledErrorLabel = styled(ErrorLabel)`
  margin-top: -10px;
  margin-left: 5px;
  margin-bottom: 15px;
`;

const StyledSelect = styled(Select)`
  margin-bottom: 15px;
  max-width: 500px;
`;

const StyledSelectLabel = styled(StyledLabel)`
  font-size: 15px;
  padding: 0 5px;
  text-align: justify;
  margin-top: 20px;
`;

const FirstStyledSelectLabel = styled(StyledSelectLabel)`
  margin-top: 0px;
`;

/** Definição de interfaces */
interface IProps {
  updateForm: Function;
  candidateToken: string;
  updateStep: Function;
  updateSpinner: Function;
  form: RegisterForm;
  ethnics: EthnicResponse[];
  setEthnics: Function;
  modalities: ModalityResponse[];
  setModalities: Function;
}

interface Step7Form {
  ethnicGroup: number;
  modality: number;
  genre_id: number;
}

interface Step7FormValid {
  ethnicGroup: boolean;
  modality: boolean;
  genre_id: boolean;
}

export interface ModalityResponse {
  id_processo_seletivo_mod_candit: number;
  modalidade_candidatura: string;
}

export interface GetModalitiesResponse {
  success: boolean;
  response: ModalityResponse[] | null;
}

export interface EthnicResponse {
  id_raca_cor: number;
  raca_cor: string;
}

export interface GetEthnicsResponse {
  success: boolean;
  response: EthnicResponse[] | null;
}

interface updateModalityEthnicResponse {
  success: boolean;
}

const Step7 = (props: IProps) => {
  const navigate = useNavigate();

  const {
    updateForm,
    candidateToken,
    updateStep,
    updateSpinner,
    form,
    ethnics,
    setEthnics,
    modalities,
    setModalities,
  } = props;

  /** Estados */
  const [formInfo, setFormInfo] = useState<Step7Form>({
    ethnicGroup: 0,
    modality: 0,
    genre_id: 0,
  });

  const [formValid, setFormValid] = useState<Step7FormValid>({
    ethnicGroup: true,
    modality: true,
    genre_id: true,
  });

  const [submitted, setSubmitted] = useState<boolean>(false);

  const [firstIteration, setFirstIteration] = useState(true);

  const [errorAlert, setErrorAlert] = useState<boolean>(false);

  /** Efeito para recuperar valores salvos no form geral */
  useEffect(() => {
    if (firstIteration) {
      Object.keys(formInfo).map((key: any) => {
        if (form.hasOwnProperty(key)) {
          if (form[key as keyof RegisterForm]) {
            setFormInfo(() => {
              return Object.assign(formInfo, {
                [key]: form[key as keyof RegisterForm],
              });
            });
          }
        }
        return null;
      });
      setFirstIteration(false);
    }
  }, [formInfo, form, firstIteration]);

  /**Efeito que obtem os valores dos selects */
  useEffect(() => {
    const getEthnics = async () => {
      try {
        const {
          data: { success, response },
        } = await randomApi.get<GetEthnicsResponse>('/ethnics/', {
          headers: headers(candidateToken),
          timeout: 30000,
        });

        if (!success) {
          updateSpinner(false);
          navigate('/erro-processo-seletivo');
          return;
        }

        setEthnics(response as EthnicResponse[]);
      } catch {
        updateSpinner(false);
        navigate('/erro-processo-seletivo');
        return;
      }
    };

    const effect = async () => {
      updateSpinner(true);
      if (ethnics.length === 0) await getEthnics();
      updateSpinner(false);
    };

    effect();
  }, [updateSpinner, navigate, candidateToken, ethnics, setEthnics]);

  /**Efeito que obtem os valores dos selects */
  useEffect(() => {
    const getModalities = async () => {
      try {
        const {
          data: { success, response },
        } = await randomApi.get<GetModalitiesResponse>(
          `/application_modalities/`,
          {
            headers: headers(candidateToken),
            timeout: 30000,
          },
        );

        if (!success) {
          updateSpinner(false);
          navigate('/erro-processo-seletivo');
          return;
        }

        setModalities(response as ModalityResponse[]);
      } catch (e: any) {
        return false;
      }
    };

    const effect = async () => {
      updateSpinner(true);
      if (modalities.length === 0) await getModalities();
      updateSpinner(false);
    };

    effect();
  }, [updateSpinner, navigate, candidateToken, modalities, setModalities]);

  /** Função do botão de voltar */
  const handleBackButton = () => {
    updateStep(5);
  };

  /** Função do botão de confirmação */
  const handleConfirmButton = useCallback(
    (event) => {
      event.preventDefault();

      setFormValid((prev: any) => {
        return { ...prev, ethnicGroup: validateSelect(formInfo.ethnicGroup) };
      });

      setFormValid((prev: any) => {
        return { ...prev, modality: validateSelect(formInfo.modality) };
      });

      setSubmitted(true);
    },
    [formInfo],
  );

  /** Função que realiza o submit final */
  const handleSubmitForm = useCallback(async () => {
    const updateModalityEthnic = async () => {
      try {
        const {
          data: { success },
        } = await randomApi.post<updateModalityEthnicResponse>(
          '/ethnics/',
          {
            modality_id: formInfo.modality,
            ethnic_id: formInfo.ethnicGroup,
          },
          {
            headers: headers(candidateToken),
            timeout: 30000,
          },
        );

        updateSpinner(false);

        if (!success) {
          updateSpinner(false);
          navigate('/erro-processo-seletivo');
          return;
        }
        updateStep(7);
      } catch {
        updateSpinner(false);
        navigate('/erro-processo-seletivo');
        return;
      }
    };
    updateSpinner(true);
    updateForm((prev: RegisterForm) => {
      return Object.assign(prev, formInfo);
    });

    await updateModalityEthnic();
  }, [
    candidateToken,
    navigate,
    updateSpinner,
    formInfo,
    updateStep,
    updateForm,
  ]);

  /** Efeito para visão do componente abrir no topo da tela */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /** Efeito de submit do primeiro formulário */
  useEffect(() => {
    const effect = async () => {
      if (submitted) {
        setErrorAlert(false);
        let minOneFalse = false;

        for (const val in formValid) {
          if (!formValid[val as keyof Step7FormValid]) minOneFalse = true;
        }

        setSubmitted(false);

        if (!minOneFalse) {
          handleSubmitForm();
        } else {
          window.scrollTo(0, 0);
          setErrorAlert(true);
        }
      }
    };

    effect();
  }, [formInfo, formValid, handleSubmitForm, submitted]);

  return (
    <MainForm onSubmit={handleConfirmButton}>
      {errorAlert ? (
        <StyledInputAlert icon={<ErrorIcon fontSize="inherit" />}>
          Por favor, revise os valores dos campos
        </StyledInputAlert>
      ) : (
        <></>
      )}
      <FormControl>
        <FirstStyledSelectLabel>
          Você se identifica com qual raça e/ou cor? *
        </FirstStyledSelectLabel>
        <StyledSelect
          value={formInfo.ethnicGroup}
          onChange={({ target: { value } }) => {
            setFormInfo((prev) => {
              setFormValid({ ...formValid, ethnicGroup: true });
              return { ...prev, ethnicGroup: value as number };
            });
          }}
          sx={
            formValid.ethnicGroup
              ? {
                  marginTop: '8px',
                  '.MuiSelect-select': {
                    color: 'white !important',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                    borderWidth: '2px !important',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                    borderWidth: '2px !important',
                  },
                }
              : {
                  marginTop: '8px',
                  '.MuiSelect-select': {
                    color: 'white',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red !important',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red',
                    borderWidth: '2px !important',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red',
                    borderWidth: '2px !important',
                  },
                }
          }
        >
          <StyledMenuItem disabled value={0}>
            <em>Selecione</em>
          </StyledMenuItem>
          {ethnics.map(({ id_raca_cor, raca_cor }) => (
            <StyledMenuItem value={id_raca_cor} key={id_raca_cor}>
              {raca_cor}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </FormControl>
      {formValid.ethnicGroup ? (
        <></>
      ) : (
        <StyledErrorLabel id="ethnicGroupError">
          É necessário selecionar uma opção étnica
        </StyledErrorLabel>
      )}
      <FormControl>
        <StyledSelectLabel>
          Qual modalidade de concorrência você deseja se candidatar? *
        </StyledSelectLabel>
        <StyledSelect
          value={formInfo.modality}
          onChange={({ target: { value } }) => {
            setFormInfo((prev) => {
              setFormValid({ ...formValid, modality: true });
              return { ...prev, modality: value as number };
            });
          }}
          sx={
            formValid.modality
              ? {
                  marginTop: '8px',
                  '.MuiSelect-select': {
                    color: 'white !important',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                    borderWidth: '2px !important',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#7ddd00',
                    borderWidth: '2px !important',
                  },
                }
              : {
                  marginTop: '8px',
                  '.MuiSelect-select': {
                    color: 'white',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red !important',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red',
                    borderWidth: '2px !important',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red',
                    borderWidth: '2px !important',
                  },
                }
          }
        >
          <StyledMenuItem disabled value={0}>
            <em>Selecione</em>
          </StyledMenuItem>
          {modalities.map(
            ({ id_processo_seletivo_mod_candit, modalidade_candidatura }) => {
              if (
                id_processo_seletivo_mod_candit === 1 ||
                (id_processo_seletivo_mod_candit === 2 &&
                  (formInfo.genre_id === 1 || formInfo.genre_id === 3)) ||
                (id_processo_seletivo_mod_candit === 3 &&
                  (formInfo.ethnicGroup === 4 || formInfo.ethnicGroup === 5))
              ) {
                return (
                  <StyledMenuItem
                    value={id_processo_seletivo_mod_candit}
                    key={id_processo_seletivo_mod_candit}
                  >
                    {modalidade_candidatura}
                  </StyledMenuItem>
                );
              }
              return null;
            },
          )}
        </StyledSelect>
      </FormControl>
      {formValid.modality ? (
        <></>
      ) : (
        <StyledErrorLabel id="modalityError">
          É necessário selecionar uma modalidade
        </StyledErrorLabel>
      )}
      <ButtonsDiv>
        <ConfirmButton type="submit">AVANÇAR</ConfirmButton>
        <BackButton type="button" onClick={handleBackButton}>
          VOLTAR
        </BackButton>
      </ButtonsDiv>
    </MainForm>
  );
};

export default Step7;
