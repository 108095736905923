import { TextField } from '@mui/material';
import styled from 'styled-components';

const InputTextField = styled(TextField)`
  margin: 8px 0 !important;
  width: min(500px, 90vw);
  & .MuiOutlinedInput-root {
    color: white !important;
    &.Mui-focused fieldset {
      /* border-color: #7ddd00; */
    }
    */ &:hover fieldset {
      /* border-color: #7ddd00; */
      border-width: 2px;
    }
    & fieldset {
      /* border-color: #7ddd00; */
      border-width: 2px;
    }
  }
  & .Mui-focused {
    color: white !important;
  }
  & label {
    color: white;
  }
`;

export default InputTextField;
