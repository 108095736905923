import Register from './register-form/Register';
// import Term from './term/Term';

interface IProps {
  page: string;
}

const Main = (props: IProps) => {
  return (
    <>
      {props.page === 'register' && <Register />}
      {/* {props.page === 'term' && <Term />} */}
    </>
  );
};

export default Main;
