import styled from 'styled-components';

const ErrorLabel = styled.label`
  color: red;
  font-size: 13px;
  margin-top: -5px;
  margin-left: 3px;
  margin-bottom: 8px;
`;

export default ErrorLabel;
