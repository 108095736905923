import Header from 'shared/components/main/header/xpe_faculdade';
import Footer from 'shared/components/main/footer/xpe_faculdade';
import { MainContainer } from 'shared/components/main/main_container';

import { BlackGlobalStyle } from 'shared/utils/styles/black_global_style';

import { RadioCanadaFontStyle } from 'shared/utils/styles/radio_canada_font_style';
import styled from 'styled-components';

const StyledP = styled.p`
  margin: 0 auto;
  width: min(500px, 80vw);
  margin-top: 15vh;
  text-align: center;
  font-size: 30px;
`;

const SuccessProcessoSeletivo = () => {
  return (
    <>
      <BlackGlobalStyle />
      <RadioCanadaFontStyle />
      <MainContainer>
        <Header home={false} />
        <StyledP>
          Sua inscrição foi concluída com sucesso! Agora é só aguardar a próxima etapa do processo seletivo.
        </StyledP>
      </MainContainer>
      <Footer />
    </>
  );
};

export default SuccessProcessoSeletivo;
